import { Component, OnInit, HostBinding, Input, OnDestroy } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteCheck } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-check',
    templateUrl: './casilla-verificacion.component.html',
    styleUrls: []
})
export class CasillaVerificacionComponent implements BaseComponenteAngular<ComponenteCheck>, OnInit, OnDestroy {
    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    private static _id = 0;
    id: string;

    constructor() {
        this.id = `casilla-verificacion-${CasillaVerificacionComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteCheck,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo;
    instanciaModelo: InstanciaModelo;
    modeloInvocante: Variable;
    fila: number;

    componente: ComponenteCheck;

    // Propiedad 'etiqueta'
    private _etiqueta: string;
    get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    // Propiedad 'valor'
    private _valor: boolean;
    get valor(): boolean {
        // console.log(`!!! ${this.id} !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ${this._valor}`);
        return this._valor;
    }
    set valor(p_valor: boolean) {
        this.actualizaValorDesdeModelo(p_valor);
    }

    actualizaValorDesdeModelo(p_valor: boolean) {
        if (this.valor === p_valor) {
            return;
        }
        this._valor = p_valor;
        if (typeof this.valorCambiado !== 'undefined') {
            this.valorCambiado(p_valor);
        }
    }

    valorCambiado = (p_valor: any) => { };

    private _modificable: boolean;
    get modificable(): boolean { return this._modificable; }
    set modificable(p_valor: boolean) { this._modificable = p_valor; }

    @Input() get soloLectura(): string {
        if (this._modificable) {
            return null;
        } else {
            return 'readonly';
        }
    }

    // Propiedad 'visible'
    private _visible: boolean;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado: boolean;
    get habilitado(): boolean { return this._habilitado; }
    set habilitado(p_valor: boolean) { this._habilitado = p_valor; }

    get deshabilitado(): boolean {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }
}
// REGISTRY.set('casilla-verificacion', CasillaVerificacionComponent);
