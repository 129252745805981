import { Component, OnInit, ViewChild, ViewContainerRef, Output, EventEmitter, ElementRef, OnDestroy, Optional, forwardRef, Inject, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TarjetaComponent } from '../tarjeta/tarjeta.component';
import { CapturaFocoService } from '../../services/CapturaFocoService';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ContenedorModalComponent } from '../contenedorModal/contenedorModal.component';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    constructor(
        private readonly capturaFocoService: CapturaFocoService,
        @Optional() @Inject(forwardRef(() => ContenedorModalComponent)) private readonly contenedorModal: ContenedorModalComponent
    ) {
        this.id = `modal-${ModalComponent._id++}`;
    }

    subscripcionModalAbre: Subscription | undefined;

    @ViewChild('dialogo', { static: true }) dialogo: ElementRef<HTMLElement> | undefined;
    @ViewChild('modal', { static: true }) modal: ElementRef<HTMLElement> | undefined;
    @ViewChild('contenedorContenido', { read: ViewContainerRef, static: true }) contenedorContenido: ViewContainerRef | undefined;
    @Output() closeModalEvent = new EventEmitter<boolean>();

    // Propiedad 'etiqueta'
    private _etiqueta: string;
    get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    get tieneEtiqueta(): boolean {
        if (this._etiqueta) {
            return true;
        } else {
            return false;
        }
    }

    private abre(p_componente: ComponentRef<BaseComponenteAngular>) {
        if (p_componente !== null) {
            const v_componenteLogico = p_componente.instance;
            if (v_componenteLogico instanceof TarjetaComponent) {
                const
                    v_tarjeta: TarjetaComponent = v_componenteLogico,
                    v_contenidoTarjeta = v_tarjeta.contenedor.detach();
                this.etiqueta = v_tarjeta.etiqueta;
                this.contenedorContenido.insert(v_contenidoTarjeta);
            } else {
                this.contenedorContenido.insert(p_componente.hostView);
            }

            // si la pestaña activa es la que contiene este modal habilitamos la animación de apertura del modal
            // if (this.pestanaService.activa.instance === this.contenedor) {
            this._estado = 'modal-abriendo';
            // }

            const v_dialogo = this.dialogo.nativeElement;

            // v_dialogo.showModal();
            setTimeout(() => this.capturaFocoService.captura(v_dialogo), 0);
        }
    }

    cierra() {
        this._estado = 'modal-cerrando';
    }

    ngOnInit() {
        console.warn(`Creando ${this.id}`);

        this.subscripcionModalAbre = this.contenedorModal.alAbrirModal.subscribe({
            next: (p_componente) => this.abre(p_componente)
        });

        this.modal.nativeElement.addEventListener('animationend', () => {
            if (this._estado === 'modal-abriendo') {
                this._estado = null;
            } else if (this._estado === 'modal-cerrando') {
                // Comprobamos si hay una pestaña activa y abrimos modal
                if (this.contenedorModal !== undefined) {
                    this.contenedorModal.cierraModal(true);
                }
                this.contenedorModal.abierto = false;
            }
        });
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
        this.contenedorContenido.detach();

        this.subscripcionModalAbre.unsubscribe();
        this.capturaFocoService.libera();

        // if (typeof this.capturaFoco !== 'undefined') {
        //this.capturaFoco.finaliza();
        // }
    }

    private _estado: 'modal-abriendo' | 'modal-cerrando' | null = null;
    get estado(): string {
        if (this._estado === null) {
            return '';
        } else {
            return this._estado;
        }
    }
}
