import { Component, OnInit, ViewContainerRef, ViewChild, HostBinding, OnDestroy } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteTarjeta, ComponenteBotonera } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';


@Component({
    selector: 'app-tarjeta',
    templateUrl: './tarjeta.component.html'
})
export class TarjetaComponent implements BaseComponenteAngular<ComponenteTarjeta>, OnInit, OnDestroy {
    private static _id = 0;
    private id: string;

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);

        if (this.componente.componentes.length > 0 && this.componente.componentes[0] instanceof ComponenteBotonera) {
            const v_botonera = this.contenedor.detach(0);
            this.contenedorTitulo.insert(v_botonera);
            this._tieneBotonera = true;
        } else {
            this._tieneBotonera = false;
        }
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    constructor() {
        this.id = `botonera-${TarjetaComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteTarjeta,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo;
    instanciaModelo: InstanciaModelo;
    modeloInvocante: Variable;
    fila: number;

    componente: ComponenteTarjeta;

    @ViewChild('contenedorTitulo', { read: ViewContainerRef, static: true  }) contenedorTitulo: ViewContainerRef;
    @ViewChild('vc', { read: ViewContainerRef, static: true }) contenedor: ViewContainerRef;

    // Propiedad 'etiqueta'
    private _etiqueta: string;
    get etiqueta(): string { return this._etiqueta; }
    set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    // Propiedad 'visible'
    private _visible: boolean;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    private _tieneBotonera: boolean;
    get tieneBotonera(): boolean {
        return this._tieneBotonera;
    }

    get conBarraTitulo(): boolean {
        if (this.etiqueta || this._tieneBotonera) {
            return true;
        } else {
            return false;
        }
    }
}
