import { Injectable, ComponentFactoryResolver, ComponentRef, ViewContainerRef, Injector, ApplicationRef } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { PestanaService } from './PestanaService';
import { ModalService } from './TarjetaService';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PestanaComponent } from '../components/pestana/pestana.component';
import { PerfilService } from './Perfilservice';
import { AppComponent } from '../../app.component';
import { Variable } from '../abstracto/acciones/variables';
import { TipoNulo } from '../abstracto/acciones/tipos';
import { RespuestaApi, Compilador, invocaAccionBase } from '../abstracto/acciones/evotec_acciones';
import { Configuracion } from '../abstracto/ejecucion/configuracion';
import { procesa } from '../abstracto/ejecucion/procesa';
import { GeneradorAngular } from '../evotec_generadorAngular';
import { PaginaService } from './PaginaService';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private readonly iconos = ['general', 'sales', 'warehouse', 'shop', 'production', 'financiero', 'organizador', 'crm'];
    private contIconos = 0;

    constructor(
        private readonly profileService: PerfilService,
        private readonly paginaService: PaginaService,
        private readonly pestanaService: PestanaService,
        private readonly modalService: ModalService,
        private readonly http: HttpClient,
        private readonly facilitadorFactoriaComponentes: ComponentFactoryResolver,
        private readonly inyectorDependencias: Injector,
        private readonly referenciaAplicacion: ApplicationRef
    ) {
    }

    // quizás esto podría ser un servicio
    getRootViewContainerRef(): ViewContainerRef {
        const appInstance: AppComponent = this.referenciaAplicacion.components[0].instance;

        if (!appInstance.viewContainerRef) {
            const appName = this.referenciaAplicacion.componentTypes[0].name;
            throw new Error(`Missing 'viewContainerRef' declaration in ${appName} constructor`);
        }

        return appInstance.viewContainerRef;
    }

    insertaNodoEn(padre) {
        return p_nodo => padre.appendChild(p_nodo);
    }

    creaNodo(opcion: any, tienePermiso: boolean, p_incluirEtiquetaEnPrimerNivel: boolean, closeFn: any, listElementClass?: string, p_nivel?: number) {
        const
            v_nivel = typeof p_nivel === 'undefined' ? 1 : p_nivel,
            self = this,
            li = document.createElement('li'),
            etiqueta = document.createTextNode(opcion.ETIQUETA),
            tieneOpciones = opcion.Opciones && opcion.Opciones.length > 0;

        tienePermiso = tienePermiso && opcion.PERMITIDO_SN === 'S';

        li.classList.add('jqx-item');
        li.id = opcion.COD;
        let
            a: HTMLAnchorElement,
            span: HTMLSpanElement,
            span2: HTMLSpanElement;

        if (!tienePermiso) {
            console.log('no tiene permiso');
            // a = document.createElement('span');
            // a.setAttribute("disabled", "disabled");
            // a.classList.add("disabled");
        } else if (tienePermiso) {
            a = document.createElement('a');
            a.classList.add('menu-opcion');
            a.href = 'javascript:void(0);';
            if (!tieneOpciones) {
                a.setAttribute('data-usmenu_cod', opcion.COD);
                a.classList.add('lastchild');
                li.setAttribute('data-usmenu_cod', opcion.COD);
                if (opcion.FORMATO) {
                    a.setAttribute('data-formato', opcion.FORMATO);
                }
            }
        }
        if (opcion.COD.length === 2) {
            span = document.createElement('span');

            const icono = 'icon-' + this.iconos[this.contIconos % this.iconos.length];
            span.classList.add(icono);
            span.classList.add('menu-opcion-icono');
            this.contIconos++;
            a.appendChild(span);

            if (p_incluirEtiquetaEnPrimerNivel) {
                span2 = document.createElement('span');
                span2.classList.add('menu-opcion-etiqueta');
                span2.appendChild(etiqueta);
                a.appendChild(span2);
            }
            li.appendChild(a);
        } else {
            a.appendChild(etiqueta);
            li.appendChild(a);
        }
        if (!tienePermiso) {
            //var ul = document.createElement("ul");
            //li.appendChild(ul);
        }
        else if (tieneOpciones) {
            var ul = document.createElement('ul');

            opcion.Opciones
                .map(opcion => self.creaNodo(opcion, tienePermiso, true, closeFn, listElementClass, v_nivel + 1))
                .forEach(self.insertaNodoEn(ul));
            li.appendChild(ul);
        }

        return li;
    }

    // private clickMenuOption = (url: string, params: any) => {
    //     this.spyMenuSandbox.apiCall(url, params);
    // }

    abreOpcion(p_usmenu_cod: string) {
        const v_perfil = this.profileService.activo;
        this.ejecuta({
            evt_evento: 'MENU_ITEM',
            evt_menu_cod: p_usmenu_cod,
            evt_culture_info: '',
            evt_empgrupo_cod: v_perfil.empgrupo_cod,
            evt_emp_cod: v_perfil.emp_cod
        });
    }

    ejecuta(p_parametros: object) {
        Configuracion.configura({
            accionBase: (p_parametros, p_activacion, p_funcionRespuesta) => {
                console.log(`Invocando AccionBase`);
                const
                    v_pestanaActiva = p_activacion.contextoLlamada.pestana();

                // this.http.post('http://soporte.spyro.es/pruebas/SpyroAPI_pruebas_rbm/evotec', p_parametros).subscribe(p_respuesta);

                if (v_pestanaActiva !== null) {
                    v_pestanaActiva.instance.muestraSpinner();
                } else {
                    this.paginaService.muestraSpinner();
                }

                console.log(p_parametros['PARAMETROS']);
                const v_resultado = new Promise<any>((p_resuelve) => {
                    this.http
                        //.post('http://soporte.spyro.es/pruebas/SpyroAPI_pruebas_rbm/evotec', p_parametros)
                        .post(`${environment.apiUrl}/evotec`, p_parametros)
                        .subscribe((p_respuesta: RespuestaApi) => {
                            setTimeout(() => {
                                if (v_pestanaActiva !== null) {
                                    v_pestanaActiva.instance.ocultaSpinner();
                                } else {
                                    this.paginaService.ocultaSpinner();
                                }
                            }, 0);
                            p_funcionRespuesta(p_respuesta).then(p_valor => p_resuelve(p_valor));
                        });
                });
                return v_resultado;
            },
            navega: (p_modeloInvocante, p_definicion, p_contextoLlamada) => {
                const v_pestanaActiva = p_contextoLlamada.pestana();

                try {
                    const
                        v_interfaz = procesa(p_modeloInvocante, p_definicion, p_contextoLlamada),

                        v_componentes = GeneradorAngular.genera(
                            v_interfaz.vista.definicion,
                            this.getRootViewContainerRef(),
                            this.facilitadorFactoriaComponentes,
                            this.inyectorDependencias,
                            this.referenciaAplicacion,
                            v_interfaz.modelo.definicion,
                            v_interfaz.modelo.instancia,
                            p_modeloInvocante,
                            undefined,
                            undefined,
                            v_pestanaActiva
                        );
                    console.log(v_interfaz);
                    console.log(v_componentes);

                    const v_referenciaComponenteRaiz = v_componentes[0];

                    // this.vc.insert(v_referenciaComponenteRaiz.hostView);
                    if (v_referenciaComponenteRaiz.instance instanceof PestanaComponent) {
                        const v_referenciaPestana = v_referenciaComponenteRaiz as ComponentRef<PestanaComponent>;
                        this.pestanaService.abre(v_referenciaPestana);
                    } else {
                        // throw new Error('No se puede abrir; no es una pestaña');
                        this.pestanaService.abreVista(v_pestanaActiva, v_referenciaComponenteRaiz);
                    }

                    // Comprueba si existe la accion 'alIniciar'. Si existe, la ejecuta de forma asincrona
                    const v_alIniciar = v_interfaz.modelo.definicion.acciones.alIniciar;
                    if (typeof v_alIniciar !== 'undefined') {
                        let v_valorRaiz: Variable;
                        if (v_interfaz.modelo.definicion.padres && v_interfaz.modelo.definicion.padres.length > 0) {
                            v_valorRaiz = v_interfaz.modelo.definicion.padres[0].convierteModelo(v_interfaz.modelo.instancia.padres[0], p_contextoLlamada);
                        } else {
                            v_valorRaiz = { tipo: new TipoNulo(), valor: null };
                        }

                        const v_modelo = v_interfaz.modelo.definicion.convierteModelo(v_interfaz.modelo.instancia, p_contextoLlamada);
                        let v_padres = [{ nombre: '@raiz', valor: v_valorRaiz }]
                            .concat((v_interfaz.modelo.definicion.padres && v_interfaz.modelo.definicion.padres.slice(0, -1).map((p_padre, p_indice) => ({ nombre: '@padre' + p_indice, valor: v_valorRaiz }))) || [])
                            .concat({ nombre: '@modelo', valor: v_modelo });
                        if (p_modeloInvocante !== undefined) {
                            v_padres = v_padres
                                .concat({ nombre: '@modeloInvocante', valor: p_modeloInvocante });
                        }

                        setTimeout(() => Compilador.ejecuta(v_alIniciar, v_padres, undefined, undefined, p_contextoLlamada), 0);
                    }
                } catch (v_error) {
                    this.getRootViewContainerRef().detach();
                    throw v_error;
                }
            },
            cierraModal: (p_contextoLlamada) => {
                const v_pestanaActiva = p_contextoLlamada.pestana();
                if (v_pestanaActiva !== undefined) {
                    v_pestanaActiva.instance.cierraModal(true);
                }
            },
            cierra: (p_contextoLlamada) => {
                if (this.modalService.modalAbierto) {
                    this.modalService.cierraModal(true);
                } else {
                    const v_pestanaActiva = p_contextoLlamada.pestana();
                    this.pestanaService.cierraVista(v_pestanaActiva);
                }
            },
            modal: (p_modeloInvocante, p_definicion, p_contextoLlamada): Promise<boolean> => {
                const v_resultado = new Promise<boolean>((p_resuelve, p_rechaza) => {
                    try {
                        const
                            v_pestanaActiva = p_contextoLlamada.pestana(),
                            v_interfaz = procesa(p_modeloInvocante, p_definicion, p_contextoLlamada),
                            v_componentes = GeneradorAngular.genera(
                                v_interfaz.vista.definicion,
                                this.getRootViewContainerRef(),
                                this.facilitadorFactoriaComponentes,
                                this.inyectorDependencias,
                                this.referenciaAplicacion,
                                v_interfaz.modelo.definicion,
                                v_interfaz.modelo.instancia,
                                p_modeloInvocante,
                                p_resuelve,
                                p_rechaza,
                                v_pestanaActiva
                            );
                        console.log(v_interfaz);
                        console.log(v_componentes);

                        this.modalService.abreModal(v_componentes[0], p_resuelve, v_pestanaActiva);
                    } finally {
                        this.getRootViewContainerRef().detach();
                    }
                });
                return v_resultado;
            }
        });

        invocaAccionBase(p_parametros, { pestana: () => null });
    }

    agenda() {
        this.abreOpcion('080160');
    }
}
