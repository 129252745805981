import { DescriptorPseudoEnlace } from './componentes';
import { Diccionario } from '../evotec_comun';
import { DefinicionModelo, Definicion } from './interfazUsuario';

export type ValorAccion =
    undefined
    | null
    | object
    | string
    | number
    | boolean
    | Accion
    | DescriptorPseudoEnlace
    // TS 3.7
    | (null | object | string | number | boolean | Accion)[]
    // | ValorAccion[]
    ;

/**
 *
 * @param p_accion
 * p_accion es de tipo Accion y devuelve que no es nulo, ni es undefined, es de la propiedad accion_id y que accion_id es de tipo string
 */
export function esAccion(p_accion: ValorAccion): p_accion is Accion {
    return p_accion !== null
        && typeof p_accion !== 'undefined'
        && p_accion.hasOwnProperty('accion_id')
        && typeof (p_accion as Accion).accion_id === 'string';
}

export type Accion =
    (AccionSecuencia
        | AccionSi
        | AccionMientras
        | AccionModelo
        | AccionModeloInvocante
        | AccionObjeto
        | AccionInstanciaTipo
        | AccionInvocaFuncion
        | AccionInvocaApi
        | AccionCreaVariable
        | AccionAtributo
        | AccionDevuelve
        | AccionVariable
        | AccionPregunta
        | AccionInforma
        | AccionNavega
        | AccionModal
        | AccionCierra
        | AccionCierraModal
        | AccionNada
    );

export interface AccionSecuencia {
    accion_id: '_secuencia';
    acciones: ValorAccion[];
    pregunta?: string;
}

export interface AccionSi {
    accion_id: '_si';
    condicion: ValorAccion;
    entonces?: ValorAccion;
    sino?: ValorAccion;
    pregunta?: string;
}

export interface AccionMientras {
    accion_id: '_mientras';
    condicion: ValorAccion;
    accion: Accion;
    pregunta?: string;
}

export interface AccionModelo {
    accion_id: '_modelo';
    pregunta?: string;
}

export interface AccionModeloInvocante {
    accion_id: '_modeloInvocante';
    pregunta?: string;
}

export interface AccionObjeto {
    accion_id: '_objeto';
    propiedades: Diccionario<ValorAccion>;
    pregunta?: string;
}

export interface AccionInstanciaTipo {
    accion_id: '_instanciaTipo';
    tipo: Accion;
    parametros: ValorAccion[];
    pregunta?: string;
}

export interface AccionInvocaFuncion {
    accion_id: '_invocaFuncion';
    funcion: string | Accion; // nombre de función o acción que evalua cuyo resultado es una función
    argumentos: ValorAccion[];
    pregunta?: string;
}

export interface AccionInvocaApi {
    accion_id: 'invocaApi';
    parametros: Accion | Diccionario<ValorAccion>;
    pregunta?: string;
}

export interface AccionNavega {
    accion_id: '_navega';
    pantalla: Definicion;
    pregunta?: string;
}

export interface AccionModal {
    accion_id: '_modal';
    pantalla: Definicion;
    pregunta?: string;
}

export interface AccionCierra {
    accion_id: 'cierra';
    pregunta?: string;
}

export interface AccionCierraModal {
    accion_id: 'cierraModal';
    ok?: boolean;
    pregunta?: string;
}

export interface AccionInforma {
    accion_id: '_informa';
    mensaje: ValorAccion;
    pregunta?: string;
}

export interface AccionCreaVariable {
    accion_id: '_creaVariable';
    nombre: string;
    valor: Accion | any;
    pregunta?: string;
}

// export interface ParametroAccionCreaFuncion {
//     nombre: string;
//     tipo: Tipo;
// }

// export interface AccionCreaFuncion {
//     accion_id: '_creaFuncion';
//     parametros?: ParametroAccionCreaFuncion[];
//     accion: ValorAccion;
//     pregunta?: string;
// }

export interface AccionAtributo {
    accion_id: '_atributo';
    objeto: ValorAccion;
    nombre: string;
    nuevoValor?: ValorAccion;
    pregunta?: string;
}

export interface AccionVariable {
    accion_id: '_variable';
    nombre: string;
    nuevoValor?: ValorAccion;
    pregunta?: string;
}

export interface AccionContexto {
    accion_id: '_contexto';
    pregunta?: string;
}

export interface AccionVuelca {
    accion_id: '_vuelca';
    pregunta?: string;
}

export interface AccionDevuelve {
    accion_id: 'devuelve';
    valores: Diccionario<ValorAccion>;
    pregunta?: string;
}

export interface AccionPregunta {
    accion_id: '_pregunta';
    modelo: DefinicionModelo;
    texto: string;
    si?: ValorAccion;
    pregunta?: string;
}

export interface AccionNada {
    accion_id: '_nada';
    pregunta?: never;
}
