import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from './services/Auth/auth.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the auth header from your auth service.
        const authToken = this.auth.token;
        // REVISAR chapucita
        let authReq: HttpRequest<any>;
        if (req.url === 'http://soporte.spyro.es/pruebas/SpyroAPI_pruebas_rbm/evotec') {
            authReq = req.clone({
                // headers: req.headers.set('sid', 'NjJhfTZyWURTDhtXQhFSAQhWXlYRDlsNWUd4VQBVVQs=')
                headers: req.headers.set('sid', authToken !== null ? authToken : '')
                    .set('Cache-Control', 'no-cache, no-store, must-revalidate')
            });
        } else {
            authReq = req.clone({
                // REVISAR se están metiendo SID a todas las peticiones, esto incluye el login.
                headers: req.headers.set('sid', authToken !== null ? authToken : '')
                    .set('Cache-Control', 'no-cache, no-store, must-revalidate')
            });
        }

        return next.handle(authReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // MMA: Mejorar
                    console.log('MMA', error);
                    if (error.statusText === 'Conexion denegada; token incorrecto' || error.statusText === 'Conexión incorrecta') {
                        this.auth.cierraSesion();
                    }
                }
                return throwError(error);
            })
        );
    }
}
