import { Component, OnInit, ViewContainerRef, ViewChild, HostBinding, Inject, ElementRef } from '@angular/core';
import { LoginService } from './loginService';
import { jqxInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxinput';
import { AuthService } from 'src/app/core/services/Auth/auth.service';
declare var particlesJS: any;

@Component({
    selector: 'app-pagina-login .spyLoginLayout',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    recuerdame_SN : boolean = false;
    @ViewChild('inputNombre', { static: true }) inputNombre: jqxInputComponent | undefined;
    @ViewChild('inputPassword', { static: true }) inputPassword: jqxInputComponent | undefined;
    @ViewChild('inputBd', { static: true }) inputBd: jqxInputComponent | undefined;
    @ViewChild('ojo', { static: true }) ojo: ElementRef | undefined;
    constructor(
        private loginService: LoginService,
        private auth: AuthService
    ) {
    }

    /**
     * Si al cargar el login existe token, llama al servicio del login y
     * loguea automáticamente
     */
    ngOnInit() {
        if (typeof this.inputNombre === 'undefined' ||
            typeof this.inputPassword === 'undefined' ||
            typeof this.inputBd === 'undefined' ||
            typeof this.ojo === 'undefined') {

            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        if (this.auth.token) {
            this.loginService.loginSuccess(this.auth.token);
        }

        particlesJS.load('particles-js', 'assets/json/particles.json', function () { });

        const v_inputPassword = this.inputPassword.elementRef.nativeElement.querySelector('input');
        v_inputPassword.setAttribute('type', 'password');

        this.ojo.nativeElement.addEventListener('click', () => this.toggle());

        setTimeout(() => {
            if (typeof this.inputNombre === 'undefined') {
                throw new Error('Error interno; el componente no se ha inicializado aún.');
            }

            /* Comprobamos si hay variables guardadas en el localStorage.
               nombre, db, y recuerdameCheck.
               Si existen establecemos sus valores en el formulario
            */
            if(localStorage.getItem('nombre') !== null) this.inputNombre.val(localStorage.getItem('nombre'));
            if(localStorage.getItem('bd') !== null) this.inputBd.val(localStorage.getItem('bd'));
            if(localStorage.getItem('recuerdameCheck') !== null && localStorage.getItem('recuerdameCheck') === 'ok') this.recuerdame_SN = true;
            this.inputNombre.selectAll();
        }, 0);
    }

    // --- recuerdame
    /**
     * Se llama a esta función cuando se clica el check de Recuérdame.
     * Si se activa, se guardan las variables en el localstorage
     * Si se desactiva, se borran las variables del localstorage
     */ 
    recuerdame(event: any){
        this.recuerdame_SN = event.target.checked;
        if(event.target.checked) {
            localStorage.setItem('nombre', this.inputNombre.val());
            localStorage.setItem('bd', this.inputBd.val());
            localStorage.setItem('recuerdameCheck', 'ok');
        } else {
            localStorage.removeItem('nombre');
            localStorage.removeItem('bd');
            localStorage.removeItem('recuerdameCheck');
        }
    }

    // --- iniciaSesion
    /**
     * Llama a la función loginIn de loginSandbox para comprobar si el
     * login es correcto y redirigir al dashboard en cuyo caso
     */
    iniciaSesion(p_formulario) {
        // Mejorar si es posible: estas dos líneas están porque 
        // si el login tira del localstorage, las variables del login se mandan vacías
        // (aunque aparecen en los campos de texto), y hay que setearlas aquí para que tengan valor.
        // No he descubierto otra manera de hacerlo. :( 
        p_formulario.controls['nombre'].setValue(this.inputNombre.val());
        p_formulario.controls['bd'].setValue(this.inputBd.val());

        this.loginService.iniciaSesion(p_formulario);
    }

    // --- Toogle campo password
    /**
     * Mostrar- Ocultar campo password
     */
    private toggle() {
        if (typeof this.inputPassword === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_inputPassword = this.inputPassword.elementRef.nativeElement.querySelector('input');
        if (v_inputPassword.getAttribute('type') === 'password') {
            v_inputPassword.setAttribute('type', 'text');
        } else {
            v_inputPassword.setAttribute('type', 'password');
        }
    }

    get iconoOjo(): string {
        if (typeof this.inputPassword === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_inputPassword = this.inputPassword.elementRef.nativeElement.querySelector('input');
        if (v_inputPassword.getAttribute('type') === 'text') {
            return 'icon-hide_password';
        } else {
            return 'icon-show_password';
        }
    }
}
