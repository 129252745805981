import { Component, OnInit, ViewChild, HostBinding, ComponentRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfirmacionService } from '../../services/ConfirmacionService';
import { jqxNotificationComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxnotification';
import { PaginaService } from '../../services/PaginaService';
import { Configuracion } from '../../abstracto/ejecucion/configuracion';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ContenedorModalComponent } from '../contenedorModal/contenedorModal.component';
import { ModalService } from '../../services/TarjetaService';

@Component({
    selector: 'app-pagina',
    templateUrl: './pagina.component.html'
})
export class PaginaComponent implements OnInit, OnDestroy {
    private static _id = 0;
    id: string;

    subscripcionConfirmacion: Subscription;

    @ViewChild('jqxnotification', { static: false }) notificador: jqxNotificationComponent;
    @ViewChild('contenedorModal', { read: ContenedorModalComponent, static: false }) contenedorModal: ContenedorModalComponent;

    constructor(
        private confirmacionService: ConfirmacionService,
        private paginaService: PaginaService,
        private tarjetaService: ModalService
    ) {
        this.id = `pagina-${PaginaComponent._id++}`;
        this.paginaService.ocultaMenu();
    }

    ngOnInit() {
        console.warn(`Creando ${this.id}`);

        Configuracion.configura({
            informa: (p_mensaje) => {
                this.confirmacionService.muestra(p_mensaje);
            }
        });

        this.subscripcionConfirmacion = this.confirmacionService.subcribeMensaje({
            next: p_mensaje => {
                if (p_mensaje !== null) {
                    const
                        v_mensaje = p_mensaje.toString(),
                        v_palabras = v_mensaje.replace(',', ' ').replace('.', ' ').split(' ');
                    if (v_palabras.length > 50) {
                        this.notificador.autoClose(false);
                    } else {
                        this.notificador.autoClose(true);
                        const v_retraso = (Math.floor(v_palabras.length / 10) + 4) * 1500; // 4 segundos + 1.5 segundos por cada 10 palabras

                        this.notificador.autoCloseDelay(v_retraso);

                        console.log(`La notificacion se cerrará en ${v_retraso / 1000} segundos.`);
                    }
                    this.notificador.elementRef.nativeElement.querySelector('#mensaje-notificacion').innerHTML = v_mensaje;
                    this.notificador.open();
                }
            }
        });

        this.tarjetaService.alAbrirModalGlobal.subscribe({
            next: ([p_componente, p_resuelve]) => {
                if (p_componente !== null) {
                    this.contenedorModal.abreModal(p_componente, p_resuelve);
                }
            }
        });
    }

    ngOnDestroy() {
        console.warn(`Destruyendo ${this.id}`);
    }

    @HostBinding('class.menu-minimizado')
    get menuMinimizado(): boolean {
        return this.paginaService.menuMinimizado;
    }

    @HostBinding('class.menu-oculto')
    get menuOculto(): boolean {
        return this.paginaService.menuOculto;
    }

    abreModal(p_componente: ComponentRef<BaseComponenteAngular>, p_resuelve: (p_valor: boolean) => void) {
        this.contenedorModal.abreModal(p_componente, p_resuelve);
    }

    cierraModal(p_ok: boolean) {
        this.contenedorModal.cierraModal(p_ok);
    }
}
