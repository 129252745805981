export interface Diccionario<TValor = any> {
    [p_clave: string]: TValor;
}

export type MiembrosDe<T> = {
    [P in keyof T]: T[P];
};

// Genera la representacion JSON de un valor.
export function comoJson(p_dato: any): string {
    if (typeof p_dato === 'undefined') {
        return '\'undefined\'';
    }

    const v_sustituyeRepeticiones = (() => {
        const v_vistos = new WeakSet();
        return (p_clave, p_valor) => {
            if (typeof p_valor === 'object' && p_valor !== null) {
                if (v_vistos.has(p_valor)) {
                    if (p_valor.toString !== Object.prototype.toString) {
                        return p_valor.toString();
                    } else {
                        return '<repetido>'; // una referencia circular es también una repetición.
                    }
                }
                v_vistos.add(p_valor);
            }
            return p_valor;
        };
    })();

    // let v_datoJson: string;
    // try {
    //     v_datoJson = JSON.stringify(p_dato, getCircularReplacer());
    // } catch {
    //     v_datoJson = '<objeto con referencias circulares>';
    // }
    // if (v_datoJson.length > 100) {
    //     v_datoJson = `${v_datoJson.slice(0, 80)}...${v_datoJson.slice(-20)}`;
    // }
    const v_datoJson = JSON.stringify(p_dato, v_sustituyeRepeticiones);
    return v_datoJson;
}

// Igualdad (recursiva) entre objetos.
// Compara dos objetos que serán iguales si ambos tienen el mismo número de propiedades y cada una de ellas son iguales.
export function igual(p_1: any, p_2: any): boolean {
    if (typeof p_1 === 'undefined') {
        return typeof p_2 === 'undefined';
    } else if (typeof p_2 === 'undefined') {
        return false;
    }

    if (p_1 === null || p_2 === null) {
        return p_1 === p_2;
    }

    if ((typeof p_1 === 'boolean' && typeof p_2 === 'boolean') ||
        (typeof p_1 === 'number' && typeof p_2 === 'number') ||
        (typeof p_1 === 'string' && typeof p_2 === 'string')) {
        return p_1 === p_2;
    } else if (p_1 instanceof Date && p_2 instanceof Date) {
        return p_1 === p_2 || p_1.getTime() === p_2.getTime();
    } else if (typeof p_1 === 'object' && typeof p_2 === 'object' && p_1 === p_2) {
        // si son el mismo objeto entonces son iguales
        return true;
    }

    const
        v_propiedades1 = Object.getOwnPropertyNames(p_1),
        v_propiedades2 = Object.getOwnPropertyNames(p_2);

    if (v_propiedades1.length !== v_propiedades2.length) {
        return false;
    }

    for (const v_nombrePropiedad of v_propiedades1) {
        if (!igual(p_1[v_nombrePropiedad], p_2[v_nombrePropiedad])) {
            return false;
        }
    }
    return true;
}

interface PixelesTexto {
    (p_texto: string, p_fuente?: string): number;
    canvas?: HTMLCanvasElement;
}

export const pixelesTexto: PixelesTexto = (p_texto: string, p_fuente: string): number => {
    const
        v_canvas = pixelesTexto.canvas || (pixelesTexto.canvas = document.createElement('canvas')),
        v_context = v_canvas.getContext('2d');
    if (v_context === null) {
        throw new Error('Error interno; sin contexto.');
    }
    v_context.font = p_fuente;
    const v_metrics = v_context.measureText(p_texto);
    return v_metrics.width;
};

const anchosCaracteres = {
    '0car': '0car',
    '1car': '2car',
    '2car': '5car',
    '4car': '7car',
    '6car': '9car',
    '8car': '11car',
    '10car': '14car',
    '15car': '20car',
    '20car': '25car',
    '25car': '30car',
    '30car': '35car',
    '35car': '40car',
    '40car': '45car',
    '60car': '65car',
};

export function pixeles(p_ancho: string) {
    if (p_ancho.endsWith('car')) {
        const
            v_ancho = anchosCaracteres[p_ancho] || p_ancho,
            v_longitud = v_ancho.slice(0, v_ancho.length - 'car'.length),
            v_pixeles = pixelesTexto('O', '') * parseInt(v_longitud, 10);
        return v_pixeles;
    }
    throw new Error(`${p_ancho} no es un ancho válido.`);
}

function formatea2digitos(p_numero: number): string {
    if (typeof p_numero !== 'number') {
        throw new Error(`'{p_numero}' no es un número.`);
    }
    if (p_numero >= 0 && p_numero < 10) {
        return `0${p_numero}`;
    } else {
        return `${p_numero}`;
    }
}

function evitaIndefinido<T>(p_valor: T | undefined, p_valorPredeterminado: T): T {
    if (typeof p_valor === 'undefined') {
        return p_valorPredeterminado;
    } else {
        return p_valor;
    }
}

const formatoFecha = /^([0-9]{4})\/([0-9]{2})\/([0-9]{2})(\s([0-9]{1,2}):([0-9]{1,2})(:([0-9]{1,2}))?)?$/;


function descomponerFecha(p_texto: string): [number, number, number, number, number, number] {
    const v_capturas = p_texto.match(formatoFecha);
    if (v_capturas === null) {
        return null;
    }

    const
        v_anyo = parseInt(v_capturas[1], 10),
        v_mes = parseInt(v_capturas[2], 10),
        v_dia = parseInt(v_capturas[3], 10),
        v_horas = typeof v_capturas[5] === 'undefined' ? 0 : parseInt(v_capturas[5], 10),
        v_minutos = typeof v_capturas[6] === 'undefined' ? 0 : parseInt(v_capturas[6], 10),
        v_segundos = typeof v_capturas[8] === 'undefined' ? 0 : parseInt(v_capturas[8], 10);

    return [v_anyo, v_mes, v_dia, v_horas, v_minutos, v_segundos];
}

export function textoEsFecha(p_texto: string): boolean {
    // if (typeof p_texto === 'undefined' || p_texto === null) {
    //     return false;
    // }

    // 27/01/2020 - Se acepta la fecha "" de manera provisional ya que SPYWEB_API las manda así. Debe corregirse.
    if (typeof p_texto === 'string' && p_texto.length === 0) {
        console.warn('"" no es una fecha válida, pero SPYWEB_API_PKG representa las fechas nulas como un texto vacío.');
        return true;
    }

    const v_capturas = p_texto.match(formatoFecha);
    if (v_capturas === null) {
        return false;
    }

    const [v_anyo, v_mes, v_dia, v_horas, v_minutos, v_segundos] = descomponerFecha(p_texto);

    return v_mes >= 1 && v_mes <= 12 &&
        v_dia >= 1 && v_dia <= 31 &&
        v_horas >= 0 && v_horas <= 23 &&
        v_minutos >= 0 && v_minutos <= 59 &&
        v_segundos >= 0 && v_segundos <= 59;
}

export function formateaFecha(p_fecha: Date | string, p_opciones?: { hora?: boolean, segundos?: boolean }): string | undefined {
    function construyeFecha(p_texto: string): Date {
        const v_fecha = descomponerFecha(p_texto);
        if (v_fecha === null) {
            throw new Error(`${comoJson(p_texto)} no es una fecha.`);
        }

        const [v_anyo, v_mes, v_dia, v_horas, v_minutos, v_segundos] = v_fecha;
        return new Date(v_anyo, v_mes - 1, v_dia, v_horas, v_minutos, v_segundos);
    }

    if (typeof p_fecha === 'undefined' || p_fecha === null) {
        return;
    }

    if (typeof p_fecha === 'string') {
        p_fecha = construyeFecha(p_fecha);
    }

    const
        v_opciones = evitaIndefinido(p_opciones, { hora: true, segundos: false }),
        v_incluirHora = evitaIndefinido(v_opciones.hora, true),
        v_incluirSegundos = evitaIndefinido(v_opciones.segundos, false),
        v_anyo = p_fecha.getFullYear(),
        v_mes = formatea2digitos(p_fecha.getMonth() + 1),
        v_dia = formatea2digitos(p_fecha.getDate()),
        v_horas = formatea2digitos(p_fecha.getHours()),
        v_minutos = formatea2digitos(p_fecha.getMinutes()),
        v_segundos = formatea2digitos(p_fecha.getSeconds());

    if (v_incluirHora) {
        if (v_incluirSegundos) {
            return `${v_anyo}/${v_mes}/${v_dia} ${v_horas}:${v_minutos}:${v_segundos}`;
        } else {
            return `${v_anyo}/${v_mes}/${v_dia} ${v_horas}:${v_minutos}`;
        }
    } else {
        return `${v_anyo}/${v_mes}/${v_dia}`;

    }
}

export function formateaHora(p_fecha: Date): string | undefined {
    if (typeof p_fecha === 'undefined') {
        return;
    }
    const
        v_horas = p_fecha.getHours(),
        v_minutos = p_fecha.getMinutes(),
        v_segundos = p_fecha.getSeconds(),
        v_ss = formatea2digitos(v_segundos),
        v_mm = formatea2digitos(v_minutos),
        v_hh = formatea2digitos(v_horas),
        v_hhmmss = `${v_hh}:${v_mm}:${v_ss}`;
    return v_hhmmss;
}
