import { Component, HostBinding, ViewRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { jqxDropDownListComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist';
import { Variable } from '../../abstracto/acciones/variables';
import { comoJson, igual } from '../../abstracto/evotec_comun';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { ComponenteDesplegable } from '../../abstracto/ejecucion/componentes';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-desplegable',
    templateUrl: './desplegable.component.html',
    styleUrls: []
})
export class DesplegableComponent implements BaseComponenteAngular<ComponenteDesplegable>, OnInit, OnDestroy {
    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    private static _id = 0;
    id: string;

    @ViewChild('jqxdropdownlist', { static: false }) _dropdownlist: jqxDropDownListComponent | undefined;

    constructor() {
        this.id = `desplegable-${DesplegableComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteDesplegable,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    private static _idOpcion = 0;

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteDesplegable | undefined;

    // Propiedad 'etiqueta'
    private _etiqueta: string | undefined;
    get etiqueta(): string | undefined { return this._etiqueta; }
    set etiqueta(p_valor: string | undefined) { this._etiqueta = p_valor; }

    // Propiedad 'seleccionado'
    private _seleccionado: any = null;
    get seleccionado(): any { return this._seleccionado; }
    set seleccionado(p_valor: any) {
        this.actualizaSeleccionado(p_valor);
    }

    actualizaSeleccionado(p_valor: any) {
        if (igual(p_valor, this._seleccionado)) {
            return;
        }

        this._seleccionado = p_valor;

        const v_indice = this.calculaIndice(p_valor);
        if (v_indice !== null) {
            this._dropdownlist.selectIndex(v_indice);
        }

        if (typeof this.seleccionadoCambiado !== 'undefined') {
            this.seleccionadoCambiado(this.seleccionado);
        }
    }

    seleccionadoCambiado = (p_valor: any) => { };

    private _modificable: boolean | undefined;
    get modificable(): boolean | undefined { return this._modificable; }
    set modificable(p_valor: boolean | undefined) { this._modificable = p_valor; }

    get soloLectura(): boolean | null {
        if (this._modificable) {
            return null;
        } else {
            return true;
        }
    }

    get valor(): string | null {
        if (igual(this._seleccionado, null)) {
            return null;
        }

        if (typeof this._opciones === 'undefined') {
            return null;
        }
        const v_coincidencias = this._opciones.filter(p_valor => igual(p_valor.valor, this._seleccionado));
        if (v_coincidencias.length === 0) {
            throw new Error('Error interno; no se ha encontrado ninguna opción en el desplegable para la selección realizada.');
        }
        return v_coincidencias[0].id;
    }

    private calculaIndice(p_valor: object): number | null {
        if (typeof this._opciones === 'undefined') {
            return null;
        }

        const v_indice = this._opciones.findIndex(p_valor2 => igual(p_valor2.valor, p_valor));
        if (v_indice === -1) {
            return null;
        }
        return v_indice;
    }

    get indiceSeleccionado(): number | null {
        if (igual(this._seleccionado, null)) {
            return null;
        }
        return this.calculaIndice(this._seleccionado);
    }

    actualizaValor(p_valorSeleccionado: string | null) {
        if (typeof this._opciones === 'undefined') {
            this.actualizaSeleccionado(undefined);
        } else {
            const v_coincidencias = this._opciones.filter(p_valor => p_valor.id === p_valorSeleccionado);
            if (v_coincidencias.length === 0) {
                throw new Error(`Error interno; no se ha encontrado la opción ${p_valorSeleccionado} en el desplegable.`);
            }
            this.actualizaSeleccionado(v_coincidencias[0].valor);
        }
    }

    private _formato = '';
    get formato(): string { return this._formato; }
    set formato(p_valor: string) { this._formato = p_valor; }

    // Propiedad 'registros'
    private _registros: object[] | undefined;
    get registros(): object[] | undefined { return this._registros; }
    set registros(p_valor: object[] | undefined) {
        this._registros = p_valor;
        this.actualizaOpciones(p_valor, this.etiquetaRegistros);
    }

    private actualizaOpciones(p_registros: object[] | undefined, p_etiquetaRegistros: string | undefined) {
        if (typeof (p_registros) === 'undefined') {
            this._opciones = undefined;
        } else {
            this._opciones = p_registros.map(p_opcion => {
                const v_opciones = {
                    id: `desplegable-${DesplegableComponent._id}-opcion-${DesplegableComponent._idOpcion++}`,
                    valor: p_opcion,
                    etiqueta: p_opcion[p_etiquetaRegistros],
                };
                return v_opciones;
            });
        }
    }

    // Propiedad 'etiquetaRegistros'
    private _etiquetaRegistros: string | undefined;
    get etiquetaRegistros(): string | undefined { return this._etiquetaRegistros; }
    set etiquetaRegistros(p_valor: string | undefined) {
        this._etiquetaRegistros = p_valor;
        this.actualizaOpciones(this.registros, p_valor);
    }

    // Propiedad 'visible'
    private _visible = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado = true;
    get habilitado(): boolean { return this._habilitado; }
    set habilitado(p_valor: boolean) { this._habilitado = p_valor; }

    @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    private _opciones: { id: string, valor: object, etiqueta: any }[];
    get opciones(): { id: string, valor: object, etiqueta: any }[] {
        return this._opciones;
    }

    alSeleccionar(p_evento: any) {
        const v_seleccionado = this._dropdownlist.getSelectedItem();
        console.log(`item = ${comoJson(v_seleccionado)}`);
        console.log(`valor = ${comoJson(v_seleccionado.value)}, etiqueta = ${v_seleccionado.label}`);
        this.actualizaSeleccionado(v_seleccionado.value);
    }
}
