import { Diccionario, comoJson } from '../evotec_comun';
import { AccionResuelta, esAccionResuelta, Compilador, ResultadoAccion } from '../acciones/evotec_acciones';
import { TipoObjeto, Miembros, TipoNulo, tipoTexto, tipoNumero, tipoLogico, tipoFecha, tipoHora, Tipo, TipoArray } from '../acciones/tipos';
import { ComponenteDependiente } from './componentes';
import { Accion } from '../especificacion/acciones';
import { Variable } from '../acciones/variables';
import { ModoEnlace, DescriptorCalculo, propiedadEstaEnlazada } from '../especificacion/componentes';
import { esTipoPredefinido, tiposPredefinidos } from '../especificacion/tipos';
import { EventoAlCambiar, esNombreAccion, Datos } from '../especificacion/interfazUsuario';

export class Cambio {
    readonly definicionModelo: Modelo;
    readonly instanciaModelo: InstanciaModelo;
    readonly propiedadModelo: string;
    readonly fila?: number;
    readonly nuevoValor: any;
    readonly valorAnterior: any;
    readonly contextoLlamada: any;

    constructor(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo,
        p_propiedadModelo: string,
        p_fila: number | undefined,
        p_nuevoValor: any,
        p_valorAnterior: any,
        p_contextoLlamada: any
    ) {
        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.propiedadModelo = p_propiedadModelo;
        this.fila = p_fila;
        this.nuevoValor = p_nuevoValor;
        this.valorAnterior = p_valorAnterior;
        this.contextoLlamada = p_contextoLlamada;
    }

    damePropiedad(): Propiedad {
        return this.definicionModelo.damePropiedad(this.propiedadModelo);
    }

    private afecta(p_dependiente: ComponenteDependiente): boolean {
        return p_dependiente.$fila === this.fila;
    }

    dependientesAfectados(): ComponenteDependiente[] {
        const v_dependientesAfectados = this.damePropiedad().dependientes.filter(p_dependiente => this.afecta(p_dependiente));
        return v_dependientesAfectados;
    }
}

export class ListaCambios {
    private tabla: Cambio[];

    constructor() {
        this.tabla = [];
    }

    push(p_cambio: Cambio): void {
        console.debug(`registrando cambio en '${p_cambio.propiedadModelo}'; de ${comoJson(p_cambio.valorAnterior)} pasa a ${comoJson(p_cambio.nuevoValor)}.`);

        this.tabla.push(p_cambio);
        if (this.tabla.length > 1000) {
            this.tabla = this.tabla.slice(1);
            console.warn('Hay muchos cambios por lo que no se tendrán en cuenta todos.');
        }
    }

    // Notifica de los cambios a los componentes de la vista que están enlazados a ellos.
    notifica() {
        if (this.tabla.length === 0) {
            // nada que notificar.
            return;
        }

        console.debug(`Notificando cambios a componentes dependientes; ${this.tabla.length} cambio(s).`);

        const v_cambios = this.tabla;
        this.tabla = [];

        v_cambios.forEach((p_cambio, p_indice) => {
            // notifico a los elementos del DOM que sean dependientes de la propiedad del modelo y de la propiedad del componente a la que afecta el cambio
            // MEJORAR: descartar cambios repetidos
            const v_dependientesAfectados = p_cambio.dependientesAfectados();

            console.debug(`Cambio ${p_indice + 1} en '${p_cambio.propiedadModelo}' afecta a ${
                comoJson(v_dependientesAfectados.map(p_dependiente => p_dependiente.describe()))}`);

            v_dependientesAfectados.forEach(p_dependiente => p_dependiente.notifica(p_cambio.instanciaModelo));
        });

        console.debug('Cambios notificados a los componentes dependientes; no hay mas cambios.');
        // cambios = [];
    }

    // Por cada cambio producido lanza el evento 'alCambiar' y notifica a la interfaz de los nuevos valores del modelo.
    aplica() {
        console.warn(`----------------------------------------aplicando cambios; ${this.tabla.length} cambio(s).`);

        // notifica a la vista de los cambios realizados al modelo
        Promise.all(this.tabla
            .filter(p_cambio => typeof p_cambio.damePropiedad().alCambiar !== 'undefined')
            .map((p_cambio, p_indice) => {
                console.debug(`cambio ${p_indice + 1} en '${p_cambio.propiedadModelo}' desencadena acción.`);

                const
                    v_propiedad = p_cambio.damePropiedad(),
                    v_alCambiar = v_propiedad.alCambiar as EventoAlCambiar;

                if (!(v_alCambiar instanceof AccionResuelta)) {
                    throw new Error(`Operación no válida; se esperaba una accion resuelta.`);
                }

                console.debug(`Ejecutando accion desencadenada por cambio en '${p_cambio.propiedadModelo}' de forma ${v_alCambiar.asincrona ? 'asincrona' : 'sincrona'}`);

                return evalua(v_alCambiar, p_cambio.instanciaModelo, p_cambio.definicionModelo, undefined, undefined, undefined, p_cambio.contextoLlamada);
            })
        ).finally(() => {
            // actualizar el modelo con los resultados obtenidos
            this.notifica();
            console.debug('Se han aplicado los cambios.');
        });
    }
}

// Lista de cambios realizados en el modelo
export const cambios = new ListaCambios();


export class Enlace {
    propiedadComponente: string;
    propiedad: string;
    modo: ModoEnlace;
    modificable: boolean;
    actualizaModelo: DescriptorCalculo;
    actualizaComponente: DescriptorCalculo;

    constructor(
        p_propiedadComponente: string,
        p_propiedad: string,
        p_modo: ModoEnlace,
        p_modificable: boolean,
        p_actualizaModelo: DescriptorCalculo,
        p_actualizaComponente: DescriptorCalculo
    ) {
        this.propiedadComponente = p_propiedadComponente;
        this.propiedad = p_propiedad;
        this.modo = p_modo;
        this.modificable = p_modificable;
        this.actualizaModelo = p_actualizaModelo;
        this.actualizaComponente = p_actualizaComponente;
    }
}

export type Propiedad = PropiedadSimple | PropiedadArray | PropiedadObjeto;

export type Tipos = Diccionario<Modelo>;
export type Acciones = Diccionario<Accion | AccionResuelta>;
export type Propiedades = Diccionario<Propiedad>;

export class PropiedadSimple {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];

    constructor(p_tipo: string, p_onChange: EventoAlCambiar | undefined) {
        this.tipo = p_tipo;
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }
}

export class PropiedadArray {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];
    elementos: string | Modelo;

    constructor(p_elementos: string | Modelo, p_onChange: EventoAlCambiar | undefined) {
        this.tipo = 'array';
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
        this.elementos = p_elementos;
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }

    enlaza(p_tipos: Diccionario<Modelo>) {
        if (esNombreTipo(this.elementos)) {
            const v_elementos = p_tipos[this.elementos];
            if (typeof v_elementos === 'undefined') {
                if (esTipoPredefinido(this.elementos)) {
                    throw new Error(`Operación no válida; no se permiten arrays de tipos predefinidos: '${this.elementos}'.`);
                }
                throw new Error(`Operación no válida; el tipo '${this.elementos}' no existe.`);
            }
            this.elementos = v_elementos;
            return this;
        } else {
            throw new Error('Operación no válida');
        }
    }
}

export function esNombreTipo(p_tipo: string | Modelo): p_tipo is string {
    return typeof p_tipo === 'string';
}

export class PropiedadObjeto {
    readonly tipo: string;
    alCambiar: EventoAlCambiar | undefined;
    readonly dependientes: ComponenteDependiente[];
    tipos: Tipos | undefined;
    propiedades: Propiedades;
    acciones: Acciones | undefined;
    padres: Modelo[] | undefined;

    constructor(
        p_tipo: string,
        p_onChange: EventoAlCambiar | undefined) {

        this.tipo = p_tipo;
        this.dependientes = [];
        if (typeof p_onChange !== 'undefined') {
            this.alCambiar = p_onChange;
        }
    }

    ejecutaAccionAlCambiar(): boolean {
        return typeof this.alCambiar !== 'undefined';
    }

    enlaza(p_tipos: Diccionario<Modelo>) {
        const v_tipo = p_tipos[this.tipo];
        this.tipos = v_tipo.tipos;
        this.propiedades = v_tipo.propiedades;
        this.acciones = v_tipo.acciones;
        this.padres = v_tipo.padres;
    }
}

type Accesor = (p_nuevoValor?: any) => any;

export class Modelo {
    acciones: Acciones;
    propiedades: Propiedades;
    tipos: Tipos;
    padres: Modelo[];

    constructor(p_acciones: Acciones, p_propiedades: Propiedades, p_tipos: Tipos, p_padres: Modelo[]) {
        this.acciones = p_acciones;
        this.propiedades = p_propiedades;
        this.tipos = p_tipos;
        this.padres = p_padres;
    }

    instanciaModelo(p_datos: Datos): InstanciaModelo {
        const v_instancia = this.instanciaModeloSinPadres(p_datos);
        this.referenciaPadresInstancia(v_instancia, []);
        return v_instancia;
    }

    // instancia el modelo a partir de su definicion y carga datos iniciales
    private instanciaModeloSinPadres(p_datos: Datos): InstanciaModelo {
        // REVISAR en modo estricto no debería ser necesaria hacer esta comprobación
        if (typeof p_datos === 'undefined') {
            return new InstanciaModelo(undefined);
        } else if (p_datos === null) {
            return new InstanciaModelo(null);
        }
        const
            v_propiedades = Object.getOwnPropertyNames(this.propiedades),
            v_valores = v_propiedades.reduce((p_instancia, p_propiedadModelo) => {
                const v_propiedad = this.propiedades[p_propiedadModelo];
                if (v_propiedad instanceof PropiedadArray) {
                    if (!(v_propiedad.elementos instanceof Modelo)) {
                        throw new Error(`Error interno; la propiedad '${p_propiedadModelo}' es de tipo 'array' pero sus elementos no son instancias de 'Modelo'`);
                    }
                    const
                        v_elementos = v_propiedad.elementos,
                        v_valor = {
                            elementos: p_datos[p_propiedadModelo] && p_datos[p_propiedadModelo].map((p_registro: Datos) => v_elementos.instanciaModeloSinPadres(p_registro)) || [],
                            actualizaElementos(p_elementos) {
                                console.debug(p_elementos);
                                this.elementos = p_elementos;
                            }
                        } as InstanciaModeloValoresArray;
                    p_instancia[p_propiedadModelo] = v_valor;
                } else {
                    const
                        v_nombreTipo = v_propiedad.tipo,
                        v_tipo = tiposPredefinidos[v_nombreTipo];
                    if (p_datos) {
                        const v_dato = p_datos[p_propiedadModelo];
                        if (typeof v_tipo !== 'undefined') {
                            if (!v_tipo.esAsignable(v_dato)) {
                                throw new Error(`El valor ${comoJson(v_dato)} no es asignable a una propiedad de tipo '${v_nombreTipo}'`);
                            }
                            p_instancia[p_propiedadModelo] = v_tipo.instancia(v_dato);
                        } else {
                            // REVISAR si no es un tipo predeterminado, habría que ver si son el mismo tipo.
                            // if (!v_dato) {
                            //    console.debug(`${p_propiedadModelo} = ${v_dato}`);
                            // }
                            // p_instancia[p_propiedadModelo] = this.tipos[v_nombreTipo].instanciaModelo(v_dato, p_padres).valores;
                            p_instancia[p_propiedadModelo] = this.tipos[v_nombreTipo].instanciaModeloSinPadres(v_dato).valores;
                        }
                    } else {
                        let v_valorVacio;
                        if (typeof v_tipo === 'undefined' || typeof v_tipo.valorVacio === 'undefined') {
                            v_valorVacio = undefined;
                        } else {
                            v_valorVacio = v_tipo.valorVacio;
                        }
                        if (!esValorPropiedadInstanciaModeloObjeto(v_valorVacio)) {
                            throw new Error(`Operación no válida; ${comoJson(v_valorVacio)} no es asignable a la propiedad de un objeto.`);
                        }
                        p_instancia[p_propiedadModelo] = v_valorVacio;
                    }
                }
                return p_instancia;
            }, {} as InstanciaModeloValores),
            v_instancia = new InstanciaModelo(v_valores);

        // this.referenciaPadresInstancia(v_instancia, [v_instancia].concat(p_padres));
        // if (this.padres.length !== v_instancia.padres.length) {
        //     debugger;
        // }
        return v_instancia;
    }

    // creo en los hijos de la instancia una referencia a esta instancia.
    private referenciaPadresInstancia(p_instanciaModelo: InstanciaModelo, p_padres: InstanciaModelo[]) {
        if (p_instanciaModelo.valores) {
            const v_propiedades = Object.getOwnPropertyNames(p_instanciaModelo.valores);
            v_propiedades.forEach((p_propiedad) => {
                const v_propiedad = this.propiedades[p_propiedad];
                if (v_propiedad instanceof PropiedadArray) {
                    const
                        v_padres = p_padres.concat(p_instanciaModelo),
                        v_elementos = p_instanciaModelo.valores[p_propiedad].elementos.map(p_fila => {
                            this.referenciaPadresInstancia(p_fila, v_padres);
                            return { ...p_fila, ...{ padres: v_padres } };
                        });
                    p_instanciaModelo.valores[p_propiedad].elementos = v_elementos;
                }
            });
        }
    }

    // devuelve la definicion de una propiedad expresada en forma de ruta
    damePropiedad(p_propiedad: string): Propiedad;

    // devuelve la definicion y el valor de una propiedad expresada en forma de ruta
    damePropiedad(p_propiedad: string, p_instanciaModelo: InstanciaModelo): {
        propiedad: Propiedad;
        valor: any;
    };

    damePropiedad(p_propiedad: string, p_instanciaModelo?: InstanciaModelo): Propiedad | {
        propiedad: Propiedad;
        valor: any;
    } {
        const v_terminos = p_propiedad.split('.'), v_primerTermino = v_terminos[0], v_restoTerminos = v_terminos.slice(1), v_propiedades = v_restoTerminos.reduce((p_anterior, p_termino) => {
            const v_propiedad = p_anterior[p_anterior.length - 1].definicion;
            if (!(v_propiedad instanceof PropiedadObjeto)) {
                throw new Error('Operación no válida; sólo los objetos tienen propiedades');
            }
            return p_anterior.concat({ nombre: p_termino, definicion: v_propiedad.propiedades[p_termino] });
        }, [{ nombre: v_primerTermino, definicion: this.propiedades[v_primerTermino] }]), v_propiedad = v_propiedades[v_propiedades.length - 1];
        if (typeof p_instanciaModelo === 'undefined') {
            return v_propiedad.definicion;
        }
        const v_valores = p_instanciaModelo.valores as InstanciaModeloValoresObjeto, v_valor = v_restoTerminos.reduce((p_anterior, p_propiedad) => {
            if (typeof p_anterior === 'undefined' || p_anterior === null) {
                return p_anterior;
            }
            const v_anterior = p_anterior as Diccionario;
            return v_anterior[p_propiedad];
        }, v_valores[v_primerTermino]);
        return {
            propiedad: v_propiedad.definicion,
            valor: v_valor
        };
    }

    // Resuelve las acciones de un modelo convirtiendolas en acciones resueltas y listas para ser invocadas, dando como
    // resultado un nuevo modelo.
    resuelveAcciones(): Modelo {
        const
            // Se resuelven las acciones definidas a nivel del modelo.
            v_acciones = Object.getOwnPropertyNames(this.acciones)
                .reduce((p_acciones, p_accion) => {
                    const v_accion = this.acciones[p_accion];
                    if (esAccionResuelta(v_accion)) {
                        throw new Error('Error interno; la acción ya está resuelta.');
                    }
                    p_acciones[p_accion] = resuelve(undefined, v_accion, [this]);
                    return p_acciones;
                }, {} as Diccionario<AccionResuelta>),
            // Se resuelven las acciones onChange de las propiedades.
            v_propiedades = Object.getOwnPropertyNames(this.propiedades)
                .reduce((p_propiedades, p_nombre) => {
                    const v_propiedad = this.damePropiedad(p_nombre);
                    if (v_propiedad.ejecutaAccionAlCambiar()) {
                        if (esNombreAccion(v_propiedad.alCambiar)) {
                            const v_alCambiar = v_acciones[v_propiedad.alCambiar];
                            // RBM 15/10/19 -> ver con LCQ
                            // if (typeof v_alCambiar === 'undefined') {
                            //     throw new Error(`Operación no válida; La acción '${v_propiedad.alCambiar}' no se ha definido en el modelo actual.`);
                            // }
                            // v_propiedad.alCambiar = v_alCambiar;
                            v_propiedad.alCambiar = v_alCambiar;
                            // FIN RBM 15/10/19 -> ver con LCQ
                        } else {
                            const v_alCambiar = resuelve(undefined, v_propiedad.alCambiar as any, [this]);
                            v_propiedad.alCambiar = v_alCambiar;
                        }
                    }
                    p_propiedades[p_nombre] = v_propiedad;
                    return p_propiedades;
                }, {} as Propiedades), v_nuevoModelo = new Modelo(v_acciones, v_propiedades, this.tipos, []);
        return v_nuevoModelo;
    }

    // Creo en los hijos de la instancia una referencia a esta instancia.
    referenciaPadres(p_padres: Modelo[]): void {
        const v_propiedades = Object.getOwnPropertyNames(this.propiedades);
        v_propiedades.forEach(p_propiedad => {
            const v_propiedad = this.damePropiedad(p_propiedad);
            if (v_propiedad instanceof PropiedadArray) {
                if (esNombreTipo(v_propiedad.elementos)) {
                    throw new Error('Operación no válida');
                }
                v_propiedad.elementos = new Modelo(v_propiedad.elementos.acciones, v_propiedad.elementos.propiedades, v_propiedad.elementos.tipos, p_padres);
            }
        });
    }

    generaProxy(p_instanciaModelo: InstanciaModelo) {
        function generaProxy(p_modelo: Modelo, p_accesor: Accesor) {
            const
                v_propiedades = Object.getOwnPropertyNames(p_modelo.propiedades),
                v_proxy = v_propiedades.reduce((p_proxy, p_propiedad) => {
                    const v_propiedad = p_modelo.damePropiedad(p_propiedad);
                    if (v_propiedad instanceof PropiedadObjeto) {
                        const v_tipo = p_modelo.tipos[v_propiedad.tipo];
                        p_proxy[p_propiedad] = generaProxy(v_tipo, (...p_argumentos) => {
                            const v_objeto = p_accesor();
                            if (p_argumentos.length === 0) {
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad];
                            } else {
                                const [v_nuevoValor] = p_argumentos;
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad] = v_nuevoValor;
                            }
                        });
                    } else if (v_propiedad instanceof PropiedadSimple) {
                        p_proxy[p_propiedad] = (...p_argumentos) => {
                            const v_objeto = p_accesor();
                            if (p_argumentos.length === 0) {
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad];
                            } else {
                                let [v_nuevoValor] = p_argumentos;
                                const v_tipo = tiposPredefinidos[v_propiedad.tipo];
                                v_nuevoValor = v_tipo.instancia(v_nuevoValor);
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad] = v_nuevoValor;
                            }
                        };
                    } else if (v_propiedad instanceof PropiedadArray) {
                        //throw new Error('No implementado aún');
                        p_proxy[p_propiedad] = (...p_argumentos) => {
                            const v_objeto = p_accesor();
                            if (p_argumentos.length === 0) {
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad];
                            } else {
                                debugger;
                                const [v_nuevoValor] = p_argumentos;
                                return typeof v_objeto === 'undefined' ? v_objeto : v_objeto[p_propiedad] = v_nuevoValor;
                            }
                        };
                    } else {
                        throw new Error('No implementado aún');
                    }
                    return p_proxy;
                }, {});
            // return v_;
            return (...p_argumentos) => {
                if (p_argumentos.length === 0) {
                    const v_valor = p_accesor();
                    return typeof v_valor === 'undefined' || v_valor === null ? v_valor : v_proxy;
                } else {
                    const [v_nuevoValor] = p_argumentos;
                    return p_accesor(v_nuevoValor);
                }
            };
        }

        const v_proxy = generaProxy(this, (...p_argumentos) => {
            if (p_argumentos.length === 0) {
                return p_instanciaModelo.valores;
            } else {
                const [v_nuevoValor] = p_argumentos;
                return p_instanciaModelo.valores = this.instanciaModelo(v_nuevoValor).valores;
            }
        });

        return v_proxy;
    }

    convierteModelo(p_instanciaModelo: InstanciaModelo, p_contextoLlamada: any) {
        // const v_this = this;

        function mapeaValor(p_instanciaModelo: InstanciaModelo, p_definicionModelo: Modelo) {
            if (p_instanciaModelo.valores === null) {
                return null;
            }

            const
                v_propiedades = Object.getOwnPropertyNames(p_instanciaModelo.valores),
                v_modelo = v_propiedades
                    .reduce((p_objeto, p_propiedad) => {
                        const v_funcion: ((p_nuevoValor: any) => any) & { observable: boolean } = p_nuevoValor => {
                            const v_propiedad = p_definicionModelo.damePropiedad(p_propiedad);
                            const v = tiposPredefinidos[v_propiedad.tipo];
                            // debugger;
                            if (typeof p_nuevoValor !== 'undefined') {
                                if (v) {
                                    p_nuevoValor = v.instancia(p_nuevoValor);
                                } /*else {
                                    const v2 = v_this.tipos[v_propiedad.tipo];
                                    if (v2) {
                                        p_nuevoValor = v2.instanciaModelo(p_nuevoValor);
                                    }
                                }*/
                            }
                            const v_valorAnterior = p_instanciaModelo.valores[p_propiedad];
                            if (typeof p_nuevoValor === 'undefined') {
                                if (v_propiedad instanceof PropiedadArray) {
                                    return v_valorAnterior.elementos.map(p_elemento => p_elemento.valores);
                                }
                                return v_valorAnterior;
                                // if (v.propiedad instanceof PropiedadObjeto) {
                                //     return convierteModelo(v.propiedad, v.valor);
                                // }
                                // return v_valorAnterior;
                            } else {
                                cambios.push(new Cambio(p_definicionModelo, p_instanciaModelo, p_propiedad, undefined, p_nuevoValor, v_valorAnterior, p_contextoLlamada));
                                if (v_propiedad instanceof PropiedadArray) {
                                    const v_tipo = v_propiedad.elementos;
                                    if (esNombreTipo(v_tipo)) {
                                        throw new Error('Error interno; no se esperaba un nombre de tipo');
                                    }
                                    let v_nuevoValor: InstanciaModelo[];
                                    if (p_nuevoValor === null) {
                                        v_nuevoValor = [];
                                    } else if (!Array.isArray(p_nuevoValor)) {
                                        throw new Error(`Error interno; se esperaba un array como nuevo valor pero se obtuvo ${comoJson(p_nuevoValor)}`);
                                    } else {
                                        v_nuevoValor = p_nuevoValor.map(p_valor => v_tipo.instanciaModeloSinPadres(p_valor));
                                    }
                                    return v_valorAnterior.actualizaElementos(v_nuevoValor);
                                } else {
                                    // REVISA aquí hay algo mal. Si la propiedad no es un array (porque entramos por el else) como puede ser que luego
                                    // se compruebe si el nombre del tipo es 'array'??
                                    // const
                                    //     v_nombreTipo = v_propiedad.tipo,
                                    //     v_tipo = tiposPredefinidos[v_nombreTipo];
                                    // if (v_nombreTipo === 'array' || typeof v_tipo === 'undefined') {
                                    //     const v_nuevoValor = p_definicionModelo.tipos[v_nombreTipo].instanciaModeloSinPadres(p_nuevoValor);
                                    //     if (v_nuevoValor && Array.isArray(v_nuevoValor)) {
                                    //         throw new Error('No es un array');
                                    //     }
                                    // }
                                    return p_instanciaModelo.valores[p_propiedad] = p_nuevoValor;
                                }
                            }
                        };
                        v_funcion.observable = true;
                        Object.defineProperty(p_objeto, p_propiedad, { value: v_funcion });
                        return p_objeto;
                    }, {});
            return v_modelo;
        }
        const
            v_tipo = this.convierteTipos(),
            v_valor = mapeaValor(p_instanciaModelo, this), v_modelo = { tipo: v_tipo, valor: v_valor };
        return v_modelo;
    }

    // REVISA hay que revisar este mapeo. Está a medias/mal y el resultado es un mapeo incompleto que podría no funcionar en todos los casos.
    convierteTipos(): TipoObjeto {
        const
            v_equivalencias: Diccionario<Tipo> = {
                'logico': tipoLogico,
                'numero': tipoNumero,
                'texto': tipoTexto,
                'fecha': tipoFecha,
                'hora': tipoHora
            },
            v_tipos = Object.getOwnPropertyNames(this.tipos || {})
                .reduce((p_objeto, p_nombreTipo) => {
                    const v_tipo = this.tipos[p_nombreTipo];
                    p_objeto[p_nombreTipo] = new TipoObjeto('objeto', Object.getOwnPropertyNames(v_tipo.propiedades)
                        .reduce((p_objeto, p_propiedad) => {
                            if (esTipoPredefinido(v_tipo.propiedades[p_propiedad].tipo)) {
                                p_objeto[p_propiedad] = v_equivalencias[v_tipo.propiedades[p_propiedad].tipo];
                            } else {
                                p_objeto[p_propiedad] = v_tipo.propiedades[p_propiedad].tipo;
                            }
                            return p_objeto;
                        }, {}));
                    return p_objeto;
                }, {} as Miembros),
            v_propiedades = Object.getOwnPropertyNames(this.propiedades || {})
                .reduce((p_objeto, p_nombrePropiedad) => {
                    const v_propiedad = this.propiedades[p_nombrePropiedad];
                    if (v_propiedad instanceof PropiedadArray) {
                        if (esNombreTipo(v_propiedad.elementos)) {
                            p_objeto[p_nombrePropiedad] = new TipoArray(v_tipos[v_propiedad.elementos]);
                        } else {
                            p_objeto[p_nombrePropiedad] = new TipoArray(v_propiedad.elementos.convierteTipos());
                        }
                    } else if (esTipoPredefinido(v_propiedad.tipo) /* || this.tipos[v_propiedad.tipo] */) {
                        p_objeto[p_nombrePropiedad] = v_equivalencias[v_propiedad.tipo];
                    } else if (typeof v_tipos[v_propiedad.tipo] !== 'undefined') {
                        p_objeto[p_nombrePropiedad] = v_tipos[v_propiedad.tipo];
                    }
                    return p_objeto;
                }, {} as Miembros),
            v_miembros = {
                ...v_tipos,
                ...v_propiedades
            },
            v_tipo = new TipoObjeto('objeto', v_miembros);
        return v_tipo;
    }
}

export interface InstanciaModeloValoresArray {
    elementos: InstanciaModelo[];
    actualizaElementos: (p_elementos: any) => void;
}

export declare type InstanciaModeloValoresObjeto = Diccionario<string | number | boolean | Diccionario>;

export type InstanciaModeloValores = InstanciaModeloValoresArray | InstanciaModeloValoresObjeto | string | number | boolean;

export class InstanciaModelo<T extends InstanciaModeloValores = InstanciaModeloValores> {
    valores: T;
    padres: InstanciaModelo[];

    constructor(p_valores: T) {
        this.valores = p_valores;
        this.padres = [];
    }

    valorPropiedad(p_definicionModelo: Modelo, p_expresionPropiedad: string) {
        const { propiedad: v_propiedad, valor: v_valor } = p_definicionModelo.damePropiedad(p_expresionPropiedad, this);

        if (v_propiedad instanceof PropiedadArray) {
            return (v_valor as InstanciaModeloValoresArray).elementos.map(p_elemento => p_elemento.valores);
        } else {
            return v_valor;
        }
    }
}

export function esValorPropiedadInstanciaModeloObjeto(p_valor: any): p_valor is InstanciaModelo | string | number | boolean {
    return p_valor instanceof InstanciaModelo ||
        typeof p_valor === 'string' ||
        typeof p_valor === 'number' ||
        typeof p_valor === 'boolean' ||
        typeof p_valor === 'undefined' ||
        p_valor === null;
}


export function resuelve(p_modeloInvocante: Variable | undefined, p_accion: Accion, p_modelos: Modelo[]): AccionResuelta {
    const
        v_modeloInvocanteTipo = p_modeloInvocante !== undefined ? p_modeloInvocante.tipo as TipoObjeto : undefined,
        v_tipoRaiz = p_modelos[Math.max(p_modelos.length - 1, 0)].convierteTipos(),
        v_tipoModelo = p_modelos[0].convierteTipos();
    let
        v_modelos = [{ nombre: '@raiz', valor: new Variable(v_tipoRaiz, null) }]
            .concat(p_modelos.slice(0, -1).map((p_modelo, p_indice) => ({
                nombre: `@padre${p_indice}`,
                valor: new Variable(p_modelo.convierteTipos(), null)
            })))
            .concat({ nombre: '@modelo', valor: new Variable(v_tipoModelo, null) });
    if (typeof v_modeloInvocanteTipo !== 'undefined') {
        v_modelos = v_modelos
            .concat({ nombre: '@modeloInvocante', valor: new Variable(v_modeloInvocanteTipo, null) });
    }

    return Compilador.resuelve(p_accion, v_modelos);
}

export function evalua(
    p_accion: Accion | AccionResuelta,
    p_instanciaModelo: InstanciaModelo,
    p_definicionModelo: Modelo,
    p_modeloInvocante: Variable | undefined,
    p_resuelve: (p_valor: any) => void,
    p_rechaza: (p_motivo: any) => void,
    p_contextoLlamada: any): ResultadoAccion | Promise<ResultadoAccion> {

    let v_valorRaiz: Variable;
    if (p_definicionModelo.padres && p_definicionModelo.padres.length > 0) {
        v_valorRaiz = p_definicionModelo.padres[0].convierteModelo(p_instanciaModelo.padres[0], p_contextoLlamada);
    } else {
        v_valorRaiz = { tipo: new TipoNulo(), valor: null };
    }

    const { tipo: v_tipoModelo, valor: v_valorModelo } = p_definicionModelo.convierteModelo(p_instanciaModelo, p_contextoLlamada);
    let v_padres = [{ nombre: '@raiz', valor: v_valorRaiz }]
        .concat((p_definicionModelo.padres && p_definicionModelo.padres.slice(0, -1).map((p_padre, p_indice) => ({ nombre: '@padre' + p_indice, valor: v_valorRaiz }))) || [])
        .concat({ nombre: '@modelo', valor: new Variable(v_tipoModelo, v_valorModelo) });
    if (p_modeloInvocante !== undefined) {
        v_padres = v_padres
            .concat({ nombre: '@modeloInvocante', valor: p_modeloInvocante });
    }

    return Compilador.ejecuta(p_accion, v_padres, p_resuelve, p_rechaza, p_contextoLlamada);
}