import { DefinicionModelo, DefinicionTipoModelo, miembroEsPropiedadArray, miembroEsPropiedadObjeto, miembroEsTipo } from './interfazUsuario';
import { Diccionario } from '../evotec_comun';
import { reescribeAccionEvento } from './reescrituraAcciones';
import { DefinicionComponente, Layout, SpyTab, SpyToolbar, SpyGrid, SpyCard, SpyLabel, SpyCheck, SpyInput, SpyLovInput, SpyButton, SpyAgenda } from './componentes';

function reescribeLayoutVertical(
    p_modelo: DefinicionModelo,
    p_componente: Layout,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.components === 'undefined') {
        throw new Error('Operación no válida; un bloque de distribución vertical debe tener contenido');
    }

    p_componente.components = p_componente.components.map(p_hijo => reescribeComponente(p_modelo, p_hijo, p_tipos))
        // ignoro los hijos 'null'. Cuando son 'null' es porque han sido descartados por no cumplir unos mínimos en la especificación.
        .filter(p_hijo => p_hijo !== null);

    // si el layout solo tiene 1 hijo devuelvo el hijo.
    if (p_componente.components.length === 1) {
        return p_componente.components[0];
    }

    return p_componente;
}

function reescribeLayoutHorizontal(
    p_modelo: DefinicionModelo,
    p_componente: Layout,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.components === 'undefined') {
        throw new Error('Operación no válida; un bloque de distribución horizontal debe tener contenido');
    }

    p_componente.components = p_componente.components.map(p_hijo => reescribeComponente(p_modelo, p_hijo, p_tipos))
        // ignoro los hijos 'null'. Cuando son 'null' es porque han sido descartados por no cumplir unos mínimos en la especificación.
        .filter(p_hijo => p_hijo !== null);

    // si el layout solo tiene 1 hijo devuelvo el hijo.
    if (p_componente.components.length === 1) {
        return p_componente.components[0];
    }

    return p_componente;
}

function reescribeSpyTab(
    p_modelo: DefinicionModelo,
    p_componente: SpyTab,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.components === 'undefined') {
        throw new Error('Operación no válida; una pestaña debe tener contenido');
    }

    p_componente.components = p_componente.components.map(p_hijo => reescribeComponente(p_modelo, p_hijo, p_tipos))
        // ignoro los hijos 'null'. Cuando son 'null' es porque han sido descartados por no cumplir unos mínimos en la especificación.
        .filter(p_hijo => p_hijo !== null);
    return p_componente;
}

function reescribeSpyToolbar(
    p_modelo: DefinicionModelo,
    p_componente: SpyToolbar,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.components === 'undefined') {
        throw new Error('Operación no válida; una botonera debe tener contenido');
    }

    if (!Array.isArray(p_componente.components) || p_componente.components.length === 0) {
        console.warn(`Se ha encontrado una botonera sin contenido por lo que no se tendrá en cuenta. En el futuro esto podría considerarse un error. Por favor, corrige la definición de la pantalla.`);
        return null;
    }

    p_componente.components = p_componente.components
        .map(p_hijo => reescribeComponente(p_modelo, p_hijo, p_tipos))
        // ignoro los hijos 'null'. Cuando son 'null' es porque han sido descartados por no cumplir unos mínimos en la especificación.
        .filter(p_hijo => p_hijo !== null);
    return p_componente;
}

function reescribeSpyList(
    p_modelo: DefinicionModelo,
    p_componente: DefinicionComponente,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    return p_componente;
}

function reescribeSpyGrid(
    p_modelo: DefinicionModelo,
    p_componente: SpyGrid,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.columnas === 'undefined') {
        throw new Error('Operación no válida; una tabla debe tener columnas');
    }

    p_componente.columnas = p_componente.columnas.map(function (p_columna) {
        if (typeof p_componente.registros !== 'undefined') {
            const
                v_registros = p_componente.registros.modelo,
                v_propiedadRegistros = p_modelo[v_registros];
            if (!miembroEsPropiedadArray(v_propiedadRegistros)) {
                throw new Error(`Operación no válida; se esperaba 'array' pero se obtuvo '${v_propiedadRegistros.tipo}'`);
            }
            const v_modeloElementos = p_modelo[v_propiedadRegistros.elementos];
            if (!miembroEsTipo(v_modeloElementos)) {
                throw new Error(`Operación no válida; se esperaba un objeto pero se obtuvo '${v_modeloElementos.tipo}'`);
            }
            if (typeof p_columna.alNavegar !== 'undefined') {
                const v_alNavegar = reescribeAccionEvento('spyGridColumn.alNavegar', p_columna.alNavegar, v_modeloElementos.propiedades, p_tipos);
                p_columna.alNavegar = v_alNavegar;
            }
            return p_columna;
        }

    });

    return p_componente;
}

function reescribeSpyCard(
    p_modelo: DefinicionModelo,
    p_componente: SpyCard,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente.components === 'undefined') {
        throw new Error('Operación no válida; una tarjeta debe tener contenido');
    }

    p_componente.components = p_componente.components.map(p_hijo => reescribeComponente(p_modelo, p_hijo, p_tipos))
        // ignoro los hijos 'null'. Cuando son 'null' es porque han sido descartados por no cumplir unos mínimos en la especificación.
        .filter(p_hijo => p_hijo !== null);
    return p_componente;
}

function reescribeSpyLabel(
    p_modelo: DefinicionModelo,
    p_componente: SpyLabel,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    return p_componente;
}

function reescribeSpyCheck(
    p_modelo: DefinicionModelo,
    p_componente: SpyCheck,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    return p_componente;
}

function reescribeSpyInput(
    p_modelo: DefinicionModelo,
    p_componente: SpyInput,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (p_componente.presenta === 'textArea') {
        p_componente.multilinea = true;
    }
    if (typeof p_componente.alNavegar !== 'undefined') {
        const v_alNavegar = reescribeAccionEvento('spyInput.alNavegar', p_componente.alNavegar, p_modelo, p_tipos);
        p_componente.alNavegar = v_alNavegar;
    }
    return p_componente;
}

function reescribeSpyLovInput(
    p_modelo: DefinicionModelo,
    p_componente: SpyLovInput,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    let v_componentes: DefinicionComponente[] = [
        {
            component: 'spyInput',
            etiqueta: p_componente.etiqueta,
            valor: p_componente.valor,
            ancho: p_componente.ancho
        },
        {
            component: 'spyButton',
            icono: 'busca',
            alPulsar: p_componente.alInvocarLov
        }
    ];

    if (typeof p_componente.valorDescripcion !== 'undefined') {
        v_componentes.push({
            component: 'spyLabel',
            valor: p_componente.valorDescripcion,
            estilos: ['descripcion-lov']
        });
    }

    if (typeof p_componente.alNavegar !== 'undefined') {
        v_componentes = v_componentes.concat({
            component: 'spyButton',
            icono: 'navega',
            alPulsar: p_componente.alNavegar
        });
    }

    const v_componente = reescribeComponente(p_modelo, {
        component: 'layoutHorizontal',
        components: v_componentes,
        estilos: ['lov' /*estilo solo informativo*/, 'sin-rellenar'].concat(p_componente.estilos)
    }, p_tipos);
    return v_componente;
}

function reescribeSpyButton(
    p_modelo: DefinicionModelo,
    p_componente: SpyButton,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    const v_alPulsar = reescribeAccionEvento('spyButton.alPulsar', p_componente.alPulsar, p_modelo, p_tipos);
    p_componente.alPulsar = v_alPulsar;
    return p_componente;
}

function reescribeSpyAgenda(
    p_modelo: DefinicionModelo,
    p_componente: SpyAgenda): DefinicionComponente {
    console.warn('!!!!!!! función reescribeAgenda--- MODIFICAR LO ANTES POSIBLE -- NO SIRVE!! >>>');
    p_componente.registros.modelo = 'USRTRABV_EVTAGENDA_registros';
    return p_componente;
}

type ReescritorComponenteLogico = (
    p_modelo: DefinicionModelo,
    p_componente: DefinicionComponente,
    p_tipos: Diccionario<DefinicionTipoModelo>) => DefinicionComponente;

type TablaReescritoresComponentesLogicos = Diccionario<ReescritorComponenteLogico>;

const v_reescritoresComponentes = {
    'layoutVertical': reescribeLayoutVertical,
    'layoutHorizontal': reescribeLayoutHorizontal,
    'spyTab': reescribeSpyTab,
    'spyToolbar': reescribeSpyToolbar,
    'spyList': reescribeSpyList,
    'spyGrid': reescribeSpyGrid,
    'spyCard': reescribeSpyCard,
    'spyLabel': reescribeSpyLabel,
    'spyCheck': reescribeSpyCheck,
    'spyInput': reescribeSpyInput,
    'spyLovInput': reescribeSpyLovInput,
    'spyButton': reescribeSpyButton,
    'spyAgenda': reescribeSpyAgenda,
} as TablaReescritoresComponentesLogicos;

export function reescribeComponente(
    p_modelo: DefinicionModelo,
    p_componente: DefinicionComponente,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionComponente {

    if (typeof p_componente === 'undefined') {
        throw new Error('No es un componente válido');
    }
    const
        v_tipo = p_componente.component,
        v_reescritorComponente = v_reescritoresComponentes[v_tipo];
    if (!v_reescritorComponente) {
        throw new Error('\'' + v_tipo + '\' no es un componente válido');
    }
    return v_reescritorComponente(p_modelo, p_componente, p_tipos);
}
