import { Diccionario, comoJson } from '../evotec_comun';
import { DefinicionModelo, DefinicionTipoModelo, miembroEsPropiedad, DefinicionAccionModelo, Definicion, extraeTiposLogicos, miembroEsTipo, miembroEsAccion, DefinicionPropiedadModelo } from './interfazUsuario';
import { esDescriptorEnlace } from './componentes';
import { esAccion, ValorAccion, Accion, AccionSecuencia, AccionInvocaFuncion, AccionSi, AccionMientras, AccionObjeto, AccionInvocaApi, AccionAtributo, AccionVariable, AccionDevuelve, AccionInforma } from './acciones';

export function reescribeAccionEvento(
    p_nombreEvento: string,
    p_accion: ValorAccion | undefined,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): Accion | undefined {

    let v_accion: ValorAccion | undefined;
    if (Array.isArray(p_accion)) {
        v_accion = {
            accion_id: '_secuencia',
            acciones: p_accion
        };
    } else {
        v_accion = p_accion;
    }
    v_accion = reescribeAccion(v_accion, p_modelo, p_tipos);
    if (!esAccion(v_accion)) {
        console.warn(`El evento '${p_nombreEvento}' no hace referencia a una acción.`);
        return undefined;
    }
    return v_accion;
}

export function reescribeModelo(p_modelo: DefinicionModelo, p_definicion: Definicion): DefinicionModelo {
    const
        v_tipos: Diccionario<string> = {
            'number': 'numero',
            'string': 'texto',
            'date': 'fecha',
            'time': 'hora'
        },
        v_tiposLogicos = extraeTiposLogicos(p_modelo),
        v_propiedadesTipoErroneo = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => !miembroEsTipo(p_modelo[p_propiedad]) &&
                !miembroEsPropiedad(p_modelo[p_propiedad], v_tiposLogicos) &&
                !miembroEsAccion(p_modelo[p_propiedad]));

    if (v_propiedadesTipoErroneo.length > 0) {
        throw new Error(`Operación no válida; Se ha encontrado al menos una propiedad que hace referencia a un tipo no conocido: ${
            comoJson(v_propiedadesTipoErroneo.map(p_propiedad => `${p_propiedad}: ${p_modelo[p_propiedad].tipo}`))
            }`);
    }
    const
        v_tiposNuevoModelo = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => miembroEsTipo(p_modelo[p_propiedad]))
            .reduce((p_nuevoModelo, p_nombrePropiedad) => {
                const
                    v_tipo = p_modelo[p_nombrePropiedad] as DefinicionTipoModelo,
                    v_nuevoTipo = {
                        tipo: v_tipo.tipo,
                        propiedades: reescribeModelo(v_tipo.propiedades, p_definicion)
                    };
                p_nuevoModelo[p_nombrePropiedad] = v_nuevoTipo;
                return p_nuevoModelo;
            }, {} as DefinicionModelo),
        v_propiedadesNuevoModelo = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => miembroEsPropiedad(p_modelo[p_propiedad], v_tiposLogicos))
            .reduce((p_nuevoModelo, p_nombrePropiedad) => {
                const
                    v_propiedad = p_modelo[p_nombrePropiedad] as DefinicionPropiedadModelo,
                    v_nombreTipo = v_propiedad.tipo;
                if (typeof v_tipos[v_nombreTipo] !== 'undefined') {
                    v_propiedad.tipo = v_tipos[v_nombreTipo];
                }
                if (v_propiedad.onChange === '') {
                    console.warn(`La propiedad ${p_nombrePropiedad} tiene un evento 'onChange' vacío.`);
                }
                p_nuevoModelo[p_nombrePropiedad] = v_propiedad;
                return p_nuevoModelo;
            }, {} as DefinicionModelo),
        v_accionesNuevoModelo = Object.getOwnPropertyNames(p_modelo)
            .filter(p_propiedad => miembroEsAccion(p_modelo[p_propiedad]))
            .reduce((p_nuevoModelo, p_nombrePropiedad) => {
                const
                    v_accion = p_modelo[p_nombrePropiedad] as DefinicionAccionModelo,
                    v_nuevaAccion = reescribeAccionModelo(p_nombrePropiedad, v_accion, p_modelo, v_tiposLogicos);
                if (typeof v_nuevaAccion !== 'undefined') {
                    p_nuevoModelo[p_nombrePropiedad] = v_nuevaAccion;
                }
                return p_nuevoModelo;
            }, {} as DefinicionModelo),
        v_nuevoModelo = { ...v_tiposNuevoModelo, ...v_propiedadesNuevoModelo, ...v_accionesNuevoModelo };
    return v_nuevoModelo;
}

function generaAccion(p_valor: ValorAccion) {
    if (typeof p_valor === 'object') {
        const
            v_propiedades = Object.getOwnPropertyNames(p_valor),
            v_accion = {
                accion_id: '_objeto',
                propiedades: v_propiedades.reduce((p_objeto, p_nombrePropiedad) => {
                    const v_propiedad = p_valor[p_nombrePropiedad];
                    if (esDescriptorEnlace(v_propiedad)) {
                        p_objeto[p_nombrePropiedad] = {
                            accion_id: '_variable',
                            nombre: `@modelo.${v_propiedad.modelo}`
                        };
                    } else if (esAccion(v_propiedad)) {
                        p_objeto[p_nombrePropiedad] = v_propiedad;
                    } else {
                        p_objeto[p_nombrePropiedad] = generaAccion(v_propiedad);
                    }

                    return p_objeto;
                }, {})
            };
        return v_accion;
    }
    return p_valor;
}

function reescribeAccionSecuencia(
    p_accion: AccionSecuencia,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionSecuencia {

    const v_accion = {
        accion_id: p_accion.accion_id,
        acciones: p_accion.acciones.map(p_accion => reescribeAccion(p_accion, p_modelo, p_tipos)),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeAccionInvocaFuncion(
    p_accion: AccionInvocaFuncion,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionInvocaFuncion {

    const v_accion = {
        accion_id: p_accion.accion_id,
        funcion: p_accion.funcion,
        argumentos: p_accion.argumentos.map(p_argumento => reescribeAccion(p_argumento, p_modelo, p_tipos)),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeAccionSi(
    p_accion: AccionSi,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionSi {

    const v_accion = {
        accion_id: p_accion.accion_id,
        condicion: p_accion.condicion,
        entonces: p_accion.entonces,
        sino: p_accion.sino,
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeAccionMientras(
    p_accion: AccionMientras,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionMientras {

    const v_accion = {
        accion_id: p_accion.accion_id,
        condicion: p_accion.condicion,
        accion: p_accion.accion,
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeAccionObjeto(
    p_accion: AccionObjeto,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionObjeto {

    const v_accion = {
        accion_id: p_accion.accion_id,
        propiedades: Object.getOwnPropertyNames(p_accion.propiedades)
            .reduce((p_objeto, p_nombrePropiedad) => {
                const v_propiedad = p_accion.propiedades[p_nombrePropiedad];
                p_objeto[p_nombrePropiedad] = reescribeAccion(v_propiedad, p_modelo, p_tipos);
                return p_objeto;
            }, {} as Diccionario<ValorAccion>),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeInvocaApi(
    p_accion: AccionInvocaApi,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionInvocaApi {

    if (esAccion(p_accion.parametros)) {
        return p_accion;
    } else {
        const
            v_parametros = p_accion.parametros,
            v_accion = {
                accion_id: p_accion.accion_id,
                parametros: Object.getOwnPropertyNames(p_accion.parametros)
                    .reduce((p_objeto, p_nombreParametro) => {
                        const
                            v_parametro = v_parametros[p_nombreParametro];
                        p_objeto[p_nombreParametro] = reescribeAccion(v_parametro, p_modelo, p_tipos);
                        return p_objeto;
                    }, {} as Diccionario),
                // parametros: {
                //     accion_id: '_objeto',
                //     propiedades: Object.getOwnPropertyNames(p_accion.parametros)
                //         .reduce((p_objeto, p_nombreParametro) => {
                //             const
                //                 v_parametro = v_parametros[p_nombreParametro];
                //             p_objeto[p_nombreParametro] = reescribeAccion(v_parametro, p_modelo, p_tipos);
                //             return p_objeto;
                //         }, {} as evotec.Diccionario)
                // },
                pregunta: p_accion.pregunta
            };
        return v_accion;
    }
}

function reescribeAccionAtributo(
    p_accion: AccionAtributo,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionAtributo {

    const v_accion = {
        accion_id: p_accion.accion_id,
        objeto: reescribeAccion(p_accion.objeto, p_modelo, p_tipos),
        nombre: p_accion.nombre,
        nuevoValor: reescribeAccion(p_accion.nuevoValor, p_modelo, p_tipos),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeAccionVariable(
    p_accion: AccionVariable,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionVariable {

    const v_accion = {
        accion_id: p_accion.accion_id,
        nombre: p_accion.nombre,
        nuevoValor: reescribeAccion(p_accion.nuevoValor, p_modelo, p_tipos),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeDevuelve(
    p_accion: AccionDevuelve,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionDevuelve {

    const v_accion = {
        accion_id: p_accion.accion_id,
        valores: Object.getOwnPropertyNames(p_accion.valores)
            .reduce((p_objeto, p_nombreValor) => {
                const v_valor = p_accion.valores[p_nombreValor];
                p_objeto[p_nombreValor] = reescribeAccion(v_valor, p_modelo, p_tipos);
                return p_objeto;
            }, {} as Diccionario<ValorAccion>),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

function reescribeInforma(
    p_accion: AccionInforma,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): AccionInforma {

    const v_accion = {
        accion_id: p_accion.accion_id,
        mensaje: reescribeAccion(p_accion.mensaje, p_modelo, p_tipos),
        pregunta: p_accion.pregunta
    };
    return v_accion;
}

const reescritoresAcciones: Diccionario = {
    '_secuencia': reescribeAccionSecuencia,
    '_invocaFuncion': reescribeAccionInvocaFuncion,
    '_si': reescribeAccionSi,
    '_mientras': reescribeAccionMientras,
    '_objeto': reescribeAccionObjeto,
    'invocaApi': reescribeInvocaApi,
    '_atributo': reescribeAccionAtributo,
    '_variable': reescribeAccionVariable,
    'devuelve': reescribeDevuelve,
    '_informa': reescribeInforma,
};

function reescribeAccion(
    p_accion: ValorAccion,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): ValorAccion {

    if (esDescriptorEnlace(p_accion)) {
        if (p_accion.modelo === '*') {
            console.warn('Modelo *');
            const
                v_propiedades = Object.getOwnPropertyNames(p_modelo)
                    .filter(p_propiedad => miembroEsPropiedad(p_modelo[p_propiedad], p_tipos)),
                v_objeto = v_propiedades.reduce((p_objeto, p_propiedad) => {
                    p_objeto[p_propiedad] = {
                        accion_id: '_variable',
                        nombre: `@modelo.${p_propiedad}`
                    };
                    return p_objeto;
                }, {});
            return {
                accion_id: '_objeto',
                propiedades: v_objeto
            };
        } else {
            return { accion_id: '_variable', nombre: `@modelo.${p_accion.modelo}` };
        }
    } else if (!esAccion(p_accion)) {
        return generaAccion(p_accion);
    }

    // En este punto 'p_accion' es una acción
    const v_reescritorAccion/*: (p_accion: ValorAccion, p_modelo: EspecificacionLogica.DefinicionModelo) => ValorAccion
            */= reescritoresAcciones[p_accion.accion_id];
    if (typeof v_reescritorAccion === 'undefined') {
        return p_accion;
    }
    let v_accion = v_reescritorAccion(p_accion, p_modelo, p_tipos);

    // si la acción tiene pregunta, la reescribimos como una nueva acción '_pregunta' donde
    // se ejecutará la acción original cuando el usuario responda afirmativamente.
    if (esAccion(v_accion) && typeof v_accion.pregunta !== 'undefined') {
        const v_texto = v_accion.pregunta;
        delete v_accion.pregunta;
        v_accion = {
            accion_id: '_pregunta',
            modelo: p_modelo,
            texto: v_texto,
            si: v_accion
        };
    }

    return v_accion;
}

function reescribeAccionModelo(
    p_nombreAccion: string,
    p_accion: ValorAccion | undefined,
    p_modelo: DefinicionModelo,
    p_tipos: Diccionario<DefinicionTipoModelo>): DefinicionAccionModelo | undefined {

    const v_accion = reescribeAccion(p_accion, p_modelo, p_tipos);
    if (!esAccion(v_accion)) {
        console.warn(`'${p_nombreAccion}' no hace referencia a una acción.`);
        return undefined;
    }
    return { tipo: 'accion', ...v_accion } as DefinicionAccionModelo;
}
