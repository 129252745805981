import { Component, HostBinding, Input, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { BaseComponenteAngular } from '../UtilidadesComponentes';
import { ComponenteBoton } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';

@Component({
    selector: 'app-boton .boton',
    templateUrl: './boton.component.html',
    styleUrls: []
})
export class BotonComponent implements BaseComponenteAngular<ComponenteBoton>, OnInit, OnDestroy {
    private static _id = 0;
    id: string;
    // iconoComponente: boolean;

    constructor() {
        this.id = `boton-${BotonComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteBoton,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo!: Modelo;
    instanciaModelo!: InstanciaModelo;
    modeloInvocante!: Variable;
    fila!: number;

    componente!: ComponenteBoton;

    // Propiedad 'etiqueta'
    private _etiqueta: string = '';
    get etiqueta(): string { return this._etiqueta; }
    @Input() set etiqueta(p_valor: string) { this._etiqueta = p_valor; }

    // Propiedad 'visible'
    private _visible: boolean = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    //@HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado: boolean = true;
    get habilitado(): boolean { return this._habilitado; }
    set habilitado(p_valor: boolean) { this._habilitado = p_valor; }

    // @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    // Propiedad 'icono'
    private _icono: string | null = null;
    get icono(): string | null { return this._icono; }
    set icono(p_valor: string | null) { this._icono = p_valor; }

    //@HostBinding ('class.boton') iconoComponente = true;
    //tieneIcono(): boolean { return this._icono === null; }

    // Evento 'alPulsar'
    // @HostListener('click') alPulsar: () => void = () => { };

    private _alPulsar: () => void = () => { };

    @HostListener('click')
    get alPulsar(): () => void {
        if (!this._habilitado) {
            return () => { };
        }
        return this._alPulsar;
    }

    set alPulsar(p_manejador: () => void) {
        this._alPulsar = p_manejador;
    }

    //@HostBinding('class.con-etiqueta') 
    get tieneEtiqueta(): boolean {
        const v_tieneEtiqueta = typeof this._etiqueta === 'string';
        return v_tieneEtiqueta;
    }

    //@HostBinding('class.con-icono') 
    get tieneIcono(): boolean {
        const v_tieneIcono = typeof this._icono === 'string';
        return v_tieneIcono;
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        const v_clases = ['boton'].concat(this.componente.estilos);
        if (this.oculto) { v_clases.push('oculto'); }
        if (typeof this._icono === 'string') { v_clases.push('con-icono'); }
        if (typeof this._etiqueta === 'string') { v_clases.push('con-etiqueta'); }
        if (typeof this._habilitado !== 'undefined' && !this._habilitado) { v_clases.push('deshabilitado'); }

        return v_clases.join(' ');
    }


    // get i(): string {
    //     return evotec.comoJson(this._icono);
    // }

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);

        if (typeof this.definicionModelo === 'undefined' ||
            typeof this.instanciaModelo === 'undefined' ||
            typeof this.fila === 'undefined' ||
            typeof this.componente === 'undefined' ||
            typeof this.modeloInvocante === 'undefined') {

            // throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        //this.iconoComponente = this.tieneIcono();
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }
}
