import { ResultadoAccion, Compilador, AccionResuelta, ObjetoAccion, resultadoAccionEsObjeto } from './evotec_acciones';
import { Activacion } from './activacion';
import { AccionSecuencia, AccionSi, AccionMientras, AccionModelo, AccionModeloInvocante, AccionObjeto, AccionInstanciaTipo, AccionInvocaFuncion, Accion, AccionModal, AccionCierra, AccionPregunta, AccionInforma, AccionInvocaApi, AccionNavega, AccionCreaVariable, AccionVariable, AccionAtributo, AccionVuelca, AccionDevuelve, AccionNada } from '../especificacion/acciones';
import { TablaSimbolos } from '../ejecucion/simbolos';
import { tipoIndeterminado, TipoObjeto, Miembros, TipoConcreto, esNombreTipo, TipoParametrizado, TipoFuncion, tipoLogico, tipoTexto, TipoPredefinido } from './tipos';
import { comoJson, Diccionario } from '../evotec_comun';
import { Configuracion, CierraModal } from '../ejecucion/configuracion';
import { Variable } from './variables';
import { Datos, Definicion } from '../especificacion/interfazUsuario';

export type Operaciones = Diccionario<(p_compilador: Compilador, p_accion: any /* Accion */, p_simbolos: TablaSimbolos) => AccionResuelta>;

type FuncionResultadoEjecucionAccion = (...argumentos: any[]) => any;

/**
 * 
 * @param p_valor 
 * devuelve el valor es de tipo function.
 */
function esFuncion(p_valor: ResultadoAccion): p_valor is FuncionResultadoEjecucionAccion {
    return typeof p_valor === 'function';
}

/**
 * 
 * @param p_valor 
 * p_valor es una promesa y devuelve que es de tipo object
 */
function esPromesa(p_valor: ResultadoAccion): p_valor is Promise<undefined | null | object | string | number | boolean | void | FuncionResultadoEjecucionAccion> {
    return p_valor !== null && typeof p_valor === 'object' && typeof p_valor.then !== 'undefined';
}


export function operacionSecuencia(p_compilador: Compilador, p_accion: AccionSecuencia, p_simbolos: TablaSimbolos): AccionResuelta {
    /*
        parámetros: acciones
    */
    if (!Array.isArray(p_accion.acciones) || p_accion.acciones.length === 0) {
        throw new Error('Error interno; una secuencia tiene que tener acciones.');
    }

    const
        v_acciones = p_accion.acciones.map(p_accionSecuencia => p_compilador.resuelve(p_accionSecuencia, p_simbolos)),
        v_asincrona = v_acciones.some(({ asincrona: p_asincrona }) => p_asincrona),
        v_tipo = v_acciones[v_acciones.length - 1].tipo,

        evaluaSecuenciaSincrona = (p_activacion: Activacion): ResultadoAccion => {
            const
                v_evalua_primera = v_acciones[0].evalua,
                v_primera = v_evalua_primera(p_activacion),
                v_resto = v_acciones.slice(1);
            return v_resto
                .reduce((p_anterior, { evalua: p_evalua_accion }) => p_evalua_accion(p_activacion), v_primera);
        },

        evaluaSecuenciaAsincrona = (p_activacion: Activacion): ResultadoAccion => {
            const
                v_evalua_primera = v_acciones[0].evalua,
                v_primera = v_evalua_primera(p_activacion),
                v_resto = v_acciones.slice(1);
            return v_resto
                .reduce(
                    (p_anterior, { evalua: p_evalua_accion }) => p_anterior.then(() => p_evalua_accion(p_activacion)),
                    Promise.resolve(v_primera));
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? evaluaSecuenciaAsincrona : evaluaSecuenciaSincrona, p_accion);
}

export function operacionSi(p_compilador: Compilador, p_accion: AccionSi, p_simbolos: TablaSimbolos): AccionResuelta {
    /*
        parámetros: condicion, [entonces], [sino]
    */
    const
        { evalua: v_evaluaCondicion, asincrona: v_condicionAsincrona } = p_compilador.resuelve(p_accion.condicion, p_simbolos),
        { evalua: v_evaluaEntonces, asincrona: v_entoncesAsincrona, tipo: v_entoncesTipo } =
            p_compilador.resuelve(p_accion.entonces, p_simbolos),
        { evalua: v_evaluaSino, asincrona: v_sinoAsincrona, tipo: v_sinoTipo } = p_compilador.resuelve(p_accion.sino, p_simbolos),
        v_asincrona = v_condicionAsincrona || v_entoncesAsincrona || v_sinoAsincrona,
        v_tipo = v_entoncesTipo === v_sinoTipo ? v_entoncesTipo : tipoIndeterminado,

        v_evaluaSiSincrona = (p_activacion: Activacion): ResultadoAccion => {
            if (v_evaluaCondicion(p_activacion)) {
                return v_evaluaEntonces && v_evaluaEntonces(p_activacion);
            } else {
                return v_evaluaSino && v_evaluaSino(p_activacion);
            }
        },

        v_evaluaSiAsincrona = (p_activacion: Activacion): Promise<ResultadoAccion> => {
            const v_resultado = v_evaluaCondicion(p_activacion);

            if (!esPromesa(v_resultado)) {
                throw new Error('Se esperaba una promesa.');
            }

            return v_resultado
                .then(p_condicion => {
                    if (p_condicion) {
                        return v_evaluaEntonces && v_evaluaEntonces(p_activacion);
                    } else {
                        return v_evaluaSino && v_evaluaSino(p_activacion);
                    }
                });
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaSiAsincrona : v_evaluaSiSincrona, p_accion);
}

export function operacionMientras(p_compilador: Compilador, p_accion: AccionMientras, p_simbolos: TablaSimbolos): AccionResuelta {
    /*
        parámetros: condicion, accion
    */
    const
        { evalua: v_evaluaCondicion, asincrona: v_condicionAsincrona } = p_compilador.resuelve(p_accion.condicion, p_simbolos),
        { evalua: v_evaluaAccion, asincrona: v_accionAsincrona } = p_compilador.resuelve(p_accion.accion, p_simbolos),
        v_asincrona = v_condicionAsincrona || v_accionAsincrona,

        v_evaluaMientrasSincrona = (p_activacion: Activacion): void => {
            while (v_evaluaCondicion(p_activacion)) {
                v_evaluaAccion(p_activacion);
            }
        },

        v_evaluaMientrasAsincrona = (p_activacion: Activacion): void => {
            const v_mientras = () => {
                const v_resultado = v_evaluaCondicion(p_activacion);

                if (!esPromesa(v_resultado)) {
                    throw new Error('Se esperaba una promesa.');
                }

                v_resultado.then(p_condicion => {
                    if (p_condicion) {
                        const v_resultadoAccion = v_evaluaAccion(p_activacion);
                        if (!esPromesa(v_resultadoAccion)) {
                            throw new Error('Se esperaba una promesa');
                        }
                        v_resultadoAccion.then(v_mientras);
                    }
                });
            };
            v_mientras();
        };

    return new AccionResuelta(v_asincrona, this.global.simbolo('vacio').tipo, v_asincrona ? v_evaluaMientrasAsincrona : v_evaluaMientrasSincrona, p_accion);
}


export function operacionModelo(p_compilador: Compilador, p_accion: AccionModelo, p_simbolos: TablaSimbolos): AccionResuelta {
    /*	recupera el valor del modelo
    */

    return p_compilador.resuelve({ accion_id: '_variable', nombre: '@modelo' }, p_simbolos);
}

export function operacionModeloInvocante(p_compilador: Compilador, p_accion: AccionModeloInvocante, p_simbolos: TablaSimbolos): AccionResuelta {
    /*	recupera el valor del modelo invocante
    */

    return p_compilador.resuelve({ accion_id: '_variable', nombre: '@modeloInvocante' }, p_simbolos);
}

export function operacionObjeto(p_compilador: Compilador, p_accion: AccionObjeto, p_simbolos: TablaSimbolos): AccionResuelta {
    /*	crea un objeto con las propiedades especificadas.
        parámetros: propiedades
    */
    const
        v_propiedades = Object.getOwnPropertyNames(p_accion.propiedades)
            .map(p_nombre => {
                const { evalua: v_evalua, asincrona: v_asincrona, tipo: v_tipo } = p_compilador.resuelve(p_accion.propiedades[p_nombre], p_simbolos);
                return {
                    nombre: p_nombre,
                    asincrona: v_asincrona,
                    tipo: v_tipo,
                    evalua: v_evalua
                };
            }),
        v_asincrona = v_propiedades.some(p_propiedad => p_propiedad.asincrona),
        v_tipo = new TipoObjeto('objeto', v_propiedades.reduce((p_anterior, { nombre: p_nombre, tipo: p_tipo }) => {
            p_anterior[p_nombre] = p_tipo;
            return p_anterior;
        }, {} as Miembros)),

        v_evaluaObjetoSincrona = (p_activacion: Activacion): ResultadoAccion => {
            const
                v_objeto = v_propiedades.reduce((p_objeto, p_propiedad) => {
                    p_objeto[p_propiedad.nombre] = p_propiedad.evalua(p_activacion);

                    // Object.defineProperty(p_objeto, p_propiedad.nombre, {
                    //     enumerable: true,
                    //     value: p_propiedad.evalua(p_activacion),
                    //     writable: true
                    // });

                    return p_objeto;
                }, {} as ObjetoAccion);
            return v_objeto;
        },

        v_evaluaObjetoAsincrona = (p_activacion: Activacion): Promise<ResultadoAccion> => {
            return Promise
                .all(v_propiedades.map(p_propiedad => ({ nombre: p_propiedad.nombre, valor: p_propiedad.evalua(p_activacion) })))
                .then(p_propiedades => p_propiedades.reduce((p_objeto, p_propiedad) => {
                    if (!esPromesa(p_propiedad.valor)) {
                        throw new Error('Se esperaba una promesa');
                    }
                    p_propiedad.valor.then(p_valor => p_objeto[p_propiedad.nombre] = p_valor);
                    return p_objeto;
                }, {} as ObjetoAccion));
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaObjetoAsincrona : v_evaluaObjetoSincrona, p_accion);
}

export function operacionInstanciaTipo(p_compilador: Compilador, p_accion: AccionInstanciaTipo, p_simbolos: TablaSimbolos): AccionResuelta {
    const { evalua: v_evalua, asincrona: v_asincrona, tipo: v_tipo } = p_compilador.resuelve(p_accion.tipo, p_simbolos);
    let v_instancia_tipo: TipoConcreto;

    if (esNombreTipo(v_tipo)) {
        throw new Error('No se esperaba un nombre de tipo.');
    }

    if (v_tipo instanceof TipoParametrizado) {
        v_instancia_tipo = v_tipo.instanciaTipo(p_accion.parametros as any, p_compilador.globales);
    } else {
        v_instancia_tipo = v_tipo;
    }
    return new AccionResuelta(v_asincrona, v_instancia_tipo, v_evalua, p_accion);
}

export function operacionInvocaFuncion(p_compilador: Compilador, p_accion: AccionInvocaFuncion, p_simbolos: TablaSimbolos): AccionResuelta {
    /*
        parámetros: funcion -> string, [argumentos]
    */

    let v_funcionAccion: Accion;

    if (typeof p_accion.funcion === 'string') {
        const v_functionRuta = Compilador.descomponerRuta(p_accion.funcion);
        v_funcionAccion = Compilador.generaAccionRuta(v_functionRuta);
    } else {
        v_funcionAccion = p_accion.funcion;
    }

    /*REVISAR-> accion_asincrona: v_accion_funcion_asincrona*/
    // tslint:disable-next-line: prefer-const
    let { evalua: v_funcionEvalua, tipo: v_funcionTipo } = p_compilador.resuelve(v_funcionAccion, p_simbolos);

    if (esNombreTipo(v_funcionTipo)) {
        throw new Error('No se esperaba un nombre de tipo.');
    }

    if ((v_funcionTipo instanceof TipoParametrizado && !(v_funcionTipo.tipo instanceof TipoFuncion)) ||
        (!(v_funcionTipo instanceof TipoParametrizado) && !(v_funcionTipo instanceof TipoFuncion))) {
        throw new Error('No puede invocarse, no es una función.');
    }

    const v_resuelveArgumentos = typeof v_funcionTipo.resuelveArgumentos === 'undefined' ?
        ((p_accion, p_contexto) => p_compilador.resuelveArgumentos(p_accion, p_contexto)) :
        ((p_accion, p_contexto) => (v_funcionTipo as TipoParametrizado | TipoFuncion).resuelveArgumentos(p_accion, p_contexto));

    const { evalua: v_argumentosEvalua, asincrona: v_argumentosAsincrona, tipo: v_argumentosTipos } =
        v_resuelveArgumentos(p_accion.argumentos, p_simbolos);

    if (v_funcionTipo instanceof TipoParametrizado) {
        v_funcionTipo = v_funcionTipo.instanciaTipo(v_argumentosTipos, p_compilador.globales);
    }

    if (!(v_funcionTipo instanceof TipoFuncion)) {
        throw new Error(`Error interno; no es una función`);
    }

    let v_tipo = v_funcionTipo.resultado;
    if (esNombreTipo(v_tipo)) {
        // v_tipo = p_compilador.globales.variable(v_tipo).tipo;
        v_tipo = p_compilador.globales.simbolo(v_tipo).tipo;
    }

    const v_asincrona = v_argumentosAsincrona || v_funcionTipo.asincrona;

    if (v_funcionTipo.parametros && (v_funcionTipo.parametros.length !== v_argumentosTipos.length)) {
        throw new Error(`Número de argumentos erroneo en la llamada a '${p_accion.funcion}'`);
    } else {
        const v_argumentosAsignables = v_funcionTipo.parametros.every((p_parametro, p_indice) => {
            if (typeof v_argumentosTipos[p_indice] === 'string' || typeof p_parametro === 'string') {
                throw new Error('Operación no válida; el tipo de argumento/parámetro no debería ser un nombre de tipo');
            }
            return v_argumentosTipos[p_indice].esAsignable(p_parametro);
        });
        if (!v_argumentosAsignables) {
            throw new Error(`Tipo de argumentos erroneo en la llamada a '${p_accion.funcion}': ${comoJson(v_funcionTipo.parametros)} y ${comoJson(v_argumentosTipos)}`);
        }
    }

    const
        v_evaluaInvocaFuncionSincrona = (p_activacion: Activacion): ResultadoAccion => {
            const v_funcion = v_funcionEvalua(p_activacion);
            if (!esFuncion(v_funcion)) {
                throw new Error('Se esperaba una función');
            }

            const
                v_argumentos = typeof v_argumentosEvalua !== 'undefined' && v_argumentosEvalua(p_activacion);
            if (v_funcion.length > v_argumentos.length) {
                return v_funcion(p_activacion, ...v_argumentos);
            } else {
                return v_funcion(...v_argumentos);
            }
        },

        v_evaluaInvocaFuncionAsincrona = (p_activacion: Activacion): Promise<ResultadoAccion> => {
            const v_funcion = v_funcionEvalua(p_activacion);
            if (!esFuncion(v_funcion)) {
                throw new Error('Se esperaba una función');
            }
            const v_argumentos = typeof v_argumentosEvalua !== 'undefined' && v_argumentosEvalua(p_activacion);
            return Promise
                .all([v_funcion, v_argumentos])
                .then(([p_funcion, p_argumentos]) => {
                    if (v_funcion.length > v_argumentos.length) {
                        return p_funcion(p_activacion, ...p_argumentos);
                    } else {
                        return p_funcion(...p_argumentos);
                    }
                });
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaInvocaFuncionAsincrona : v_evaluaInvocaFuncionSincrona, p_accion);
}

export function operacionInvocaApi(p_compilador: Compilador, p_accion: AccionInvocaApi, p_simbolos: TablaSimbolos): AccionResuelta {
    /* delega-convierte invocaApi en invocaFuncion */

    const v_accion = p_compilador.resuelve({
        accion_id: '_invocaFuncion',
        funcion: 'navega',
        argumentos: [{ accion_id: '_objeto', propiedades: p_accion.parametros }]
    } as AccionInvocaFuncion);

    return v_accion;
}

export function operacionCreaVariable(p_compilador: Compilador, p_accion: AccionCreaVariable, p_simbolos: TablaSimbolos): AccionResuelta {
    /* Crea la variable asignándole el valor inicial 'valor'
        parámetros: nombre, valor
    */

    const
        { evalua: v_evaluaValor, asincrona: v_valorAsincrona, tipo: v_valorTipo } = p_compilador.resuelve(p_accion.valor, p_simbolos),
        v_asincrona = v_valorAsincrona,
        v_tipo = v_valorTipo;

    if (esNombreTipo(v_tipo)) {
        throw new Error(`Error interno; No se esperaba un nombre de tipo (${v_tipo})`);
    }

    const
        v_ranura = p_simbolos.creaSimbolo(p_accion.nombre, v_tipo),

        v_evaluaCreaVariableSincrona = (p_activacion: Activacion): ResultadoAccion => {
            const v_valor = typeof v_evaluaValor !== 'undefined' && v_evaluaValor(p_activacion);
            return p_activacion.ranura(v_ranura, v_valor);
        },

        v_evaluaCreaVariableAsincrona = (p_activacion: Activacion): Promise<ResultadoAccion> => {
            const v_valor = typeof v_evaluaValor !== 'undefined' && v_evaluaValor(p_activacion);
            return Promise
                .resolve(v_valor)
                .then(p_valor => p_activacion.ranura(v_ranura, p_valor));
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaCreaVariableAsincrona : v_evaluaCreaVariableSincrona, p_accion);
}

// operacionCreaFuncion = (p_accion: AccionCreaFuncion, p_contexto: Contexto): AccionResuelta => {
//     /*
//         parametros -> (string | {nombre: string, tipo: string})[], accion
//     */
//     const
//         v_contexto = new Contexto(p_contexto.ambitoGlobal),
//         v_parametros = p_accion.parametros || [];

//     v_parametros.forEach(p_parametro => v_contexto.creaNombre(p_parametro.nombre, p_parametro.tipo));

//     const
//         { evalua: v_evalua_accion, asincrona: v_accion_asincrona, tipo: v_accion_tipo } =
//             this.resuelve(p_accion.accion, v_contexto),
//         v_tiposParametros = v_parametros.map(p_parametro => p_parametro.tipo),
//         v_tipo = new TipoFuncion(v_tiposParametros, v_accion_tipo),
//         v_numero_ranuras = v_contexto.numeroRanuras - p_contexto.numeroRanuras,

//         v_evalua_crea_funcion = (p_activacion: Activacion): ResultadoAccion => {
//             return (...p_argumentos: any[]) => {
//                 p_activacion.creaRegistro(v_numero_ranuras, p_argumentos);
//                 const /*v_activacion = new Activacion(p_activacion.ranuras.concat(p_argumentos)),*/
//                     v_resultado = v_evalua_accion(p_activacion);
//                 p_activacion.eliminaRegistro(v_numero_ranuras);
//                 return v_resultado;
//             };
//         };
//     return new AccionResuelta(false/*v_asincrona*/, v_tipo, /* REVISAR accion_asincrona: v_asincrona, */v_evalua_crea_funcion);
// }

export function operacionVariable(p_compilador: Compilador, p_accion: AccionVariable, p_simbolos: TablaSimbolos): AccionResuelta {
    /*	Si se especifica 'nuevoValor', busca la variable en la cadena de contextos y le asigna valor. Si no existe se
        eleva una excepción.
        Si no se especifica 'nuevoValor', devuelve el valor de una variable buscando en toda la cadena de contextos
        parámetros: nombre -> string, [nuevoValor]
    */

    const v_componentesRuta = Compilador.descomponerRuta(p_accion.nombre);

    // si el nombre de la variable no denota una ruta, lo tratamos como una variable
    if (v_componentesRuta.length === 1) {
        const v_nombre = p_simbolos.simbolo(p_accion.nombre);
        if (typeof v_nombre === 'undefined') {
            throw new Error(`Variable '${p_accion.nombre}' no encontrada`);
        }
        let v_tipo = v_nombre.tipo;
        if (esNombreTipo(v_tipo)) {
            // v_tipo = p_compilador.globales.variable(v_tipo).tipo;
            v_tipo = p_compilador.globales.simbolo(v_tipo).tipo;
        }

        const
            v_ranura = v_nombre.ranura,
            { evalua: v_evaluaNuevoValor, asincrona: v_nuevoValorAsincrona, tipo: v_nuevoValorTipo } =
                p_compilador.resuelve(p_accion.nuevoValor, p_simbolos),
            v_asincrona = v_nuevoValorAsincrona || false;

        if (typeof p_accion.nuevoValor !== 'undefined' && !v_nuevoValorTipo.esAsignable(v_tipo)) {
            throw new Error('No se puede asignar un valor de tipo \'' + v_nuevoValorTipo + '\' a una variable de tipo \'' + comoJson(v_tipo) + '\'');
        }

        const
            v_evaluaVariableSincrona = (p_activacion: Activacion) => {
                const
                    v_nuevoValor = v_evaluaNuevoValor && v_evaluaNuevoValor(p_activacion),
                    v_valor = p_activacion.ranura(v_ranura, v_nuevoValor);

                if (typeof v_valor === 'undefined') {
                    console.log(`La variable '${p_accion.nombre}' no tiene valor definido`);
                }
                return v_valor;
            },

            v_evaluaVariableAsincrona = (p_activacion: Activacion) => {
                const v_nuevoValor = v_evaluaNuevoValor && v_evaluaNuevoValor(p_activacion);
                return Promise
                    .resolve(v_nuevoValor)
                    .then((p_nuevoValor) => {
                        const v_valor = p_activacion.ranura(v_ranura, p_nuevoValor);
                        if (typeof v_valor === 'undefined') {
                            console.log(`La variable '${p_accion.nombre}' no tiene valor definido`);
                        }
                        return v_valor;
                    });
            };

        return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaVariableAsincrona : v_evaluaVariableSincrona, p_accion);
    } else { // si el nombre de la variable denota una ruta, sobreescribimos esta acción por otra.
        const v_nuevaAccion = Compilador.generaAccionRuta(v_componentesRuta, p_accion.nuevoValor);
        return p_compilador.resuelve(v_nuevaAccion, p_simbolos);
    }
}

export function operacionAtributo(p_compilador: Compilador, p_accion: AccionAtributo, p_simbolos: TablaSimbolos): AccionResuelta {
    /*	devuelve el valor de un atributo de 'objeto'. Si se especifica 'nuevoValor', primero se asigna ese valor al atributo.
        parámetros: objeto, nombre, [nuevoValor]
    */
    const
        { evalua: v_evaluaObjeto, asincrona: v_objetoAsincrona, tipo: v_objetoTipo } =
            p_compilador.resuelve(p_accion.objeto, p_simbolos),
        v_nombre = p_accion.nombre,
        { evalua: v_evaluaNuevoValor, asincrona: v_nuevoValorAsincrona, tipo: v_nuevoValorTipo } =
            p_compilador.resuelve(p_accion.nuevoValor, p_simbolos),
        v_asincrona = v_objetoAsincrona || v_nuevoValorAsincrona;

    if (esNombreTipo(v_objetoTipo) || !(v_objetoTipo instanceof TipoPredefinido || v_objetoTipo instanceof TipoObjeto)) {
        throw new Error(`No se puede obtener el atributo '${p_accion.nombre}'; No es un objeto o no tiene miembros: ${comoJson(v_objetoTipo)}`);
    }

    // let v_tipo = (v_objetoTipo && v_objetoTipo.miembros && v_objetoTipo.miembros[v_nombre]) || tipoIndeterminado;
    let v_tipo = v_objetoTipo.miembros[v_nombre];
    if (typeof v_tipo === 'undefined') {
        // throw new Error(`'${v_nombre}' no es un miembro del objeto ${comoJson(v_objetoTipo)}`);
        throw new Error(`'${v_nombre}' no es un miembro de '${v_objetoTipo.etiqueta}': ${comoJson(Object.getOwnPropertyNames(v_objetoTipo.miembros))}`);
    }
    if (esNombreTipo(v_tipo)) {
        // v_tipo = p_compilador.globales.variable(v_tipo).tipo;
        v_tipo = p_compilador.globales.simbolo(v_tipo).tipo;
    }

    if (typeof p_accion.nuevoValor !== 'undefined' && !v_nuevoValorTipo.esAsignable(v_tipo)) {
        // throw new Error('No se puede asignar un valor de tipo \'' + JSON.stringify(v_nuevo_valor_tipo) + '\' a una variable de tipo \'' + JSON.stringify(v_tipo) + '\'');
        console.warn(`No se puede asignar un valor de tipo ${comoJson(v_nuevoValorTipo)} a una variable de tipo ${comoJson(v_tipo)}`);
    }

    const
        v_evaluaAtributo = (p_objeto: ResultadoAccion, p_nombre: string, p_nuevoValor: ResultadoAccion) => {
            if (!resultadoAccionEsObjeto(p_objeto)) {
                return;
            }

            let v_valor: any;
            if (p_objeto === null) {
                v_valor = null;
            } else {
                v_valor = p_objeto[p_nombre];
            }

            if (typeof v_valor === 'function' && v_valor.observable) {
                if (typeof p_nuevoValor === 'undefined') {
                    return v_valor();
                } else {
                    return v_valor(p_nuevoValor);
                }
            } else if (typeof p_nuevoValor === 'undefined') {
                return v_valor;
            } else {
                if (p_objeto === null) {
                    return null;
                }
                return p_objeto[p_nombre] = p_nuevoValor;
            }
        },

        v_evaluaAtributoSincrona = (p_activacion: Activacion): ResultadoAccion => {
            const
                v_objeto = v_evaluaObjeto(p_activacion),
                v_nuevoValor = v_evaluaNuevoValor && v_evaluaNuevoValor(p_activacion),
                v_resultado = v_evaluaAtributo(v_objeto, v_nombre, v_nuevoValor);
            return v_resultado;
        },

        v_evaluaAtributoAsincrona = (p_activacion: Activacion): Promise<ResultadoAccion> => {
            const
                v_objeto = v_evaluaObjeto(p_activacion),
                v_nuevoValor = v_evaluaNuevoValor && v_evaluaNuevoValor(p_activacion);
            return Promise
                .all([v_objeto, v_nuevoValor])
                .then(([p_objeto, p_nuevoValor]) => {
                    const v_resultado = v_evaluaAtributo(p_objeto, v_nombre, p_nuevoValor);
                    return v_resultado;
                });
        };

    return new AccionResuelta(v_asincrona, v_tipo, v_asincrona ? v_evaluaAtributoAsincrona : v_evaluaAtributoSincrona, p_accion);
}

// operacionInvoca = (p_accion, p_contexto: Contexto): AccionResuelta => {
//     /*
//         parámetros: metodo, verbo, [parametros], [cabeceras]
//     */
//     const
//         { evalua: v_evalua_metodo, asincrona: v_metodo_asincrona } = this.resuelve(p_accion.metodo, p_contexto),
//         { evalua: v_evalua_verbo, asincrona: v_verbo_asincrona } = this.resuelve(p_accion.verbo, p_contexto),
//         { evalua: v_evalua_parametros, asincrona: v_parametros_asincrona } = this.resuelve(p_accion.parametros, p_contexto),
//         { evalua: v_evalua_cabeceras, asincrona: v_cabeceras_asincrona } = this.resuelve(p_accion.cabeceras, p_contexto);

//     function trata_parametros(p_parametros, p_verbo) {
//         if (p_verbo === 'get') {
//             // console.log(p_parametros);
//             const v_parametros = Object.getOwnPropertyNames(p_parametros)
//                 .reduce(function (p_objeto, p_propiedad) {

//                     const v_valor = p_parametros[p_propiedad];
//                     // console.log(p_propiedad + " -> " + typeof v_valor);
//                     p_objeto[p_propiedad] = typeof v_valor === 'object' ? JSON.stringify(v_valor) : v_valor;
//                     return p_objeto;
//                 }, {});
//             // console.log(v_parametros);
//             return v_parametros;
//         } else {
//             return p_parametros;
//         }
//     }
//     return {
//         asincrona: true,
//         tipo: 'indeterminado',
//         evalua: function (p_activacion: Activacion) {
//             const v_metodo = v_evalua_metodo(p_activacion),
//                 v_verbo = v_evalua_verbo(p_activacion),
//                 v_parametros = v_evalua_parametros && v_evalua_parametros(p_activacion),
//                 v_cabeceras = v_evalua_cabeceras && v_evalua_cabeceras(p_activacion);
//             return Promise
//                 .all([v_metodo, v_verbo, v_parametros, v_cabeceras])
//                 .then(function ([p_metodo, p_verbo, p_parametros, p_cabeceras]) {
//                     return new Promise((p_resolver, p_rechazar) =>
//                         $.ajax('http://soporte.spyro.es/pruebas/spyroapi_pruebas/' + p_metodo, {
//                             method: p_verbo,
//                             data: trata_parametros(p_parametros, p_verbo),
//                             headers: p_cabeceras,
//                             success: function (p_resultado) {
//                                 if (p_resultado.Ok) {
//                                     p_resolver(p_resultado.Objeto);
//                                 } else {
//                                     p_rechazar();
//                                 }
//                             },
//                             error: p_rechazar
//                         }));
//                 });
//         }
//     };
// }

export function operacionContexto(): AccionResuelta {
    // 	/*	ejecuta una acción dentro de un nuevo contexto.
    //
    // 		parámetros: accion
    // 	*/
    // 	let { evalua: v_evalua_accion, asincrona: v_accion_asincrona, tipo: v_accion_tipo } = v_compilador.resuelve(p_accion.accion, p_contexto),
    // 		v_asincrona = v_accion_asincrona,
    // 		v_tipo = v_accion_tipo;
    //
    // 	function evalua_contexto(p_activacion) {
    // 		let v_nueva_activacion = new Activacion(p_activacion.ranuras);
    // 		return v_evalua_accion(v_nueva_activacion);
    // 	}
    //
    // 	return {
    // 		asincrona: v_asincrona,
    // 		tipo: v_tipo,
    // 		evalua: evalua_contexto
    // 	};

    throw new Error('No implementado');
}

export function operacionVuelca(p_compilador: Compilador, p_accion: AccionVuelca, p_simbolos: TablaSimbolos): AccionResuelta {
    /* vuelca el contenido del contexto en la consola */
    return new AccionResuelta(false, p_compilador.globales.simbolo('vacio').tipo, p_activacion => {
        console.log('Volcando contexto: (...)');
        console.group();
        console.log(comoJson(p_simbolos));
        console.groupEnd();
        console.group();
        console.log(comoJson(p_activacion));
        console.groupEnd();
    }, p_accion);
}

export function operacionDevuelve(p_compilador: Compilador, p_accion: AccionDevuelve, p_simbolos: TablaSimbolos): AccionResuelta {
    // devuelve los valores al modelo invocante
    const
        v_valores = Object.getOwnPropertyNames(p_accion.valores),
        v_acciones = v_valores.map(p_propiedad => {
            const v_valorValor = p_accion.valores[p_propiedad];
            return {
                accion_id: '_atributo',
                objeto: { accion_id: '_variable', nombre: '@modeloInvocante' },
                nombre: p_propiedad,
                nuevoValor: v_valorValor
            };
        }),
        v_accion = p_compilador.resuelve({
            accion_id: '_secuencia',
            acciones: v_acciones
        }, p_simbolos);
    return new AccionResuelta(v_accion.asincrona, v_accion.tipo, p_activacion => v_accion.evaluaYNotificaCambios(p_activacion), p_accion);
}

export function operacionNavega(p_compilador: Compilador, p_accion: AccionNavega, p_simbolos: TablaSimbolos): AccionResuelta {
    if (typeof Configuracion.navega === 'undefined') {
        throw new Error(`No se ha definido "Configuracion.navega"`);
    }
    const
        v_modelo = p_simbolos.simbolo('@modelo'),
        v_evaluaNavega = (p_activacion: Activacion) => {
            // creamos y navegamos a la pantalla según su definición
            // si existe lexema
            const v_modeloInvocante = typeof v_modelo === 'undefined' ? undefined : new Variable(v_modelo.tipo, p_activacion.ranura(v_modelo.ranura));
            Configuracion.navega(v_modeloInvocante, p_accion.pantalla, p_activacion.contextoLlamada);
        };

    return new AccionResuelta(true, tipoIndeterminado, v_evaluaNavega, p_accion);
}

export function operacionModal(p_compilador: Compilador, p_accion: AccionModal, p_simbolos: TablaSimbolos): AccionResuelta {
    if (typeof Configuracion.modal === 'undefined') {
        throw new Error(`No se ha definido "Configuracion.modal"`);
    }
    const
        v_modelo = p_simbolos.simbolo('@modelo'),
        v_evaluaModal = (p_activacion: Activacion) => {
            // creamos el modal según su definición
            // si existe lexema
            const v_modeloInvocante = typeof v_modelo === 'undefined' ? undefined : new Variable(v_modelo.tipo, p_activacion.ranura(v_modelo.ranura));

            return Configuracion.modal(v_modeloInvocante, p_accion.pantalla, p_activacion.contextoLlamada);
        };

    return new AccionResuelta(true, tipoLogico, v_evaluaModal, p_accion);
}

export function operacionCierra(p_compilador: Compilador, p_accion: AccionCierra, p_simbolos: TablaSimbolos): AccionResuelta {
    if (typeof Configuracion.cierra === 'undefined') {
        throw new Error(`No se ha definido "Configuracion.cierra"`);
    }
    const
        v_evaluaCierra = (p_activacion: Activacion) => {
            Configuracion.cierra(p_activacion.contextoLlamada);
        };

    return new AccionResuelta(true, tipoIndeterminado, v_evaluaCierra, p_accion);
}

export function operacionCierraModal(p_compilador: Compilador, p_accion: CierraModal, p_simbolos: TablaSimbolos): AccionResuelta {
    if (typeof Configuracion.cierraModal === 'undefined') {
        throw new Error(`No se ha definido "Configuracion.cierraModal"`);
    }
    const
        v_evaluaCierraModal = (p_activacion: Activacion) => {
            Configuracion.cierraModal(p_activacion.contextoLlamada);
        };

    return new AccionResuelta(true, tipoIndeterminado, v_evaluaCierraModal, p_accion);
}

export function operacionPregunta(p_compilador: Compilador, p_accion: AccionPregunta, p_simbolos: TablaSimbolos): AccionResuelta {
    const v_modelo = p_simbolos.simbolo('@modelo');
    if (typeof v_modelo === 'undefined') {
        throw new Error('Error interno; no hay modelo en este contexto.');
    }
    const
        v_ranuraModelo = v_modelo.ranura,
        v_evaluaPregunta = (p_activacion: Activacion): any => {
            const
                v_valorModelo = p_activacion.ranura(v_ranuraModelo),
                v_datos = Object.getOwnPropertyNames(v_valorModelo)
                    .filter(p_propiedad => p_accion.modelo[p_propiedad].tipo !== 'accion' && p_accion.modelo[p_propiedad].tipo !== 'tipo')
                    .reduce((p_datos, p_propiedad) => {
                        p_datos[p_propiedad] = v_valorModelo[p_propiedad]();
                        return p_datos;
                    }, {} as Datos),

                v_modalPregunta: Definicion = {
                    modelo: p_accion.modelo,
                    datos: v_datos,
                    vista: {
                        component: 'layoutVertical',
                        components: [
                            { component: 'spyLabel', valor: p_accion.texto },
                            {
                                component: 'layoutHorizontal',
                                estilos: ['al-final'],
                                components: [
                                    {
                                        component: 'spyButton',
                                        etiqueta: 'Si',
                                        alPulsar: {
                                            accion_id: '_secuencia',
                                            acciones: [
                                                p_accion.si,
                                                // Esto solo es útil en los casos en los que la acción en 'p_accion.si' es una acción sincrona.
                                                {
                                                    accion_id: 'devuelve',
                                                    valores: Object
                                                        .getOwnPropertyNames(v_valorModelo)
                                                        .reduce((p_valores, p_propiedad) => {
                                                            p_valores[p_propiedad] = { modelo: p_propiedad };
                                                            return p_valores;
                                                        }, {} as { [p_clave: string]: { modelo: string } })
                                                },
                                                { accion_id: 'cierraModal', p_ok: true },
                                            ]
                                        }
                                    },
                                    {
                                        component: 'spyButton', etiqueta: 'No', alPulsar: { accion_id: 'cierraModal' }
                                    }
                                ]
                            }
                        ]
                    }
                };

            const v_modeloInvocante = { tipo: v_modelo.tipo, valor: p_activacion.ranura(v_modelo.ranura) };
            return Configuracion.modal(v_modeloInvocante, v_modalPregunta, p_activacion.contextoLlamada);
        };

    return new AccionResuelta(true, tipoLogico, v_evaluaPregunta, p_accion);
}

export function operacionInforma(p_compilador: Compilador, p_accion: AccionInforma, p_simbolos: TablaSimbolos): AccionResuelta {
    if (typeof Configuracion.informa === 'undefined') {
        throw new Error(`No se ha definido "Configuracion.informa"`);
    }

    const { evalua: v_mensajeEvalua, asincrona: v_mensajeAsincrono, tipo: v_mensajeTipo } = p_compilador.resuelve(p_accion.mensaje, p_simbolos);

    if (!v_mensajeTipo.esAsignable(tipoTexto)) {
        throw new Error(`Error interno; al informar el mensaje debe ser un texto.`);
    }

    const
        v_evaluaSincrono = (p_activacion: Activacion): ResultadoAccion => {
            const v_mensaje = v_mensajeEvalua(p_activacion);
            Configuracion.informa(v_mensaje);
        },
        v_evaluaAsincrono = (p_activacion: Activacion): ResultadoAccion => {
            const v_mensaje = v_mensajeEvalua(p_activacion);
            v_mensaje.then(p_mensaje => Configuracion.informa(p_mensaje));
        },
        v_evalua = v_mensajeAsincrono ? v_evaluaAsincrono : v_evaluaSincrono;

    // se define como una operación sincrona a pesar de que realmente es una operacion asincrona para no bloquear 
    // la operación que la invoque, por ejemplo para que no se bloqueen el resto de operaciones en caso de que 
    // forme parte de una secuencia.
    return new AccionResuelta(false, tipoIndeterminado, v_evalua, p_accion);
}

export function operacionNada(p_compilador: Compilador, p_accion: AccionNada): AccionResuelta {
    const
        v_evaluaNada = () => {
            // Nada
        };
    return new AccionResuelta(true, tipoIndeterminado, v_evaluaNada, p_accion);
}
