import { Diccionario } from '../evotec_comun';
import { ValorAccion } from '../especificacion/acciones';
import { Ambito, TablaSimbolos } from '../ejecucion/simbolos';
import { Activacion } from './activacion';

export type NombreTipo = string;

/**
 *
 * @param p_tipo
 * Define como string la variable p_tipo
 */
export function esNombreTipo(p_tipo: Tipo | NombreTipo): p_tipo is NombreTipo {
    return typeof p_tipo === 'string';
}

interface ITipo {
    // Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los argumentos de tipo proporcionados.
    resuelve(p_parametros: NombreTipo[], p_argumentos: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto;
    esAsignable(p_asignarA: Tipo): boolean;
}


declare type ResultadoAccion = any;
declare type FuncionAccionResuelta = (p_activacion: Activacion) => ResultadoAccion;

type ResuelveArgumentos = (p_accion: ValorAccion[], p_simbolos: TablaSimbolos) => {
    asincrona: boolean;
    tipo: Tipo[];
    evalua: FuncionAccionResuelta;
};

/**
 * Clase TipoNulo implementado por ITipo
 */
export class TipoNulo implements ITipo {
    /**
     *
     * @param p_parametrosFormales
     * @param p_parametrosActuales
     * @param p_globales
     * Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los parametros de tipo proporcionados.
     */
    resuelve(p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto {
        return this;
    }

    esAsignable(p_asignarA: Tipo): boolean {
        return true;
    }
}

/**
 * Clase TipoPredefinido implementado por ITipo.
 */
export class TipoPredefinido implements ITipo {
    readonly etiqueta: string;
    miembros?: Miembros;

    constructor(p_etiqueta: string, p_miembros?: Miembros) {
        this.etiqueta = p_etiqueta;
        this.miembros = p_miembros;
    }

    /**
     *
     * @param p_parametrosFormales
     * @param p_parametrosActuales
     * @param p_globales
     *  Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los parametros de tipo proporcionados.
     */
    resuelve(p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto {
        return this;
    }

    toString(): string {
        return this.etiqueta;
    }

    esAsignable(p_asignarA: Tipo): boolean {
        return this === p_asignarA || p_asignarA === tipoIndeterminado;
    }
}

export const tipoIndeterminado = new TipoPredefinido('indeterminado');


/**
 * Clase TipoFuncion implementado por ITipo.
 */
export class TipoFuncion implements ITipo {
    readonly parametros: (Tipo | NombreTipo)[];
    readonly resultado: Tipo | NombreTipo;
    readonly asincrona: boolean;
    readonly resuelveArgumentos?: ResuelveArgumentos;

    constructor(
        p_parametros: (Tipo | NombreTipo)[],
        p_resultado: Tipo | NombreTipo,
        p_asincrona?: boolean,
        p_resuelveArgumentos?: ResuelveArgumentos) {
        this.parametros = p_parametros;
        this.resultado = p_resultado;
        this.asincrona = typeof p_asincrona !== 'undefined' && p_asincrona;
        this.resuelveArgumentos = p_resuelveArgumentos;
    }

    /**
     *
     * @param p_parametrosFormales
     * @param p_parametrosActuales
     * @param p_globales
     * Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los parametros de tipo proporcionados.
     */
    resuelve(p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto {
        const
            v_parametros = this.parametros
                .map(p_parametro => resuelveTipo(p_parametro, p_parametrosFormales, p_parametrosActuales, p_globales)),
            v_resultado = resuelveTipo(this.resultado, p_parametrosFormales, p_parametrosActuales, p_globales),
            v_tipo = new TipoFuncion(v_parametros, v_resultado, this.asincrona);
        return v_tipo;
    }

    esAsignable(p_asignarA: Tipo): boolean {
        if (this === p_asignarA || p_asignarA === tipoIndeterminado) {
            return true;
        } else if (p_asignarA instanceof TipoFuncion) {
            if (typeof this.resultado === 'string' || typeof p_asignarA.resultado === 'string') {
                throw new Error('Operación no válida; el tipo de resultado no debería ser un nombre de tipo');
            }
            if (!this.resultado.esAsignable(p_asignarA.resultado)) {
                return false;
            }

            if (this.parametros.length !== p_asignarA.parametros.length) {
                return false;
            }

            const v_parametrosAsignables = this.parametros.every((p_parametro, p_indice) => {
                const v_asignarAParametro = p_asignarA.parametros[p_indice];
                if (typeof p_parametro === 'string' || typeof v_asignarAParametro === 'string') {
                    throw new Error('Operación no válida; el tipo de parámetro no debería ser un nombre de tipo');
                }

                return p_parametro.esAsignable(v_asignarAParametro);
            });
            if (!v_parametrosAsignables) {
                return true;
            }
        }
        return false;
    }
}

export const tipoNulo = new TipoNulo();

export const tipoLogico = new TipoPredefinido('lógico');
export const tipoNumero = new TipoPredefinido('número');
export const tipoTexto = new TipoPredefinido('texto');
export const tipoFecha = new TipoPredefinido('fecha');
export const tipoHora = new TipoPredefinido('hora');

/**
 * Clase TipoObjeto implementado por ITipo.
 */
export class TipoObjeto implements ITipo {
    readonly etiqueta: string;
    readonly miembros: Miembros;

    constructor(p_etiqueta: string, p_miembros: Miembros) {
        this.etiqueta = p_etiqueta;
        this.miembros = p_miembros;
    }

    /**
     *
     * @param p_parametrosFormales
     * @param p_parametrosActuales
     * @param p_globales
     *  Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los parametros de tipo proporcionados.
     */
    resuelve(p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto {
        const v_miembros = Object.getOwnPropertyNames(this.miembros)
            .reduce((p_anterior, p_nombre) => {
                p_anterior[p_nombre] = resuelveTipo(this.miembros[p_nombre], p_parametrosFormales, p_parametrosActuales, p_globales);
                return p_anterior;
            }, {} as Miembros),
            v_tipo = new TipoObjeto(this.etiqueta, v_miembros);
        return v_tipo;
    }

    toString(): string {
        return this.etiqueta;
    }

    esAsignable(p_asignarA: Tipo): boolean {
        if (this === p_asignarA || p_asignarA === tipoIndeterminado) {
            return true;
        }
        return false;
    }
}

/**
 * Clase de TipoParametrizado
 */
export class TipoParametrizado {
    readonly parametrosFormales: NombreTipo[];
    readonly tipo: TipoConcreto;

    constructor(p_parametrosFormales: NombreTipo[], p_tipo: TipoConcreto) {
        this.parametrosFormales = p_parametrosFormales;
        this.tipo = p_tipo;
    }

    // static TipoParametrizado Crea(p_parametrosFormales: NombreTipo[], p_tipo: TipoConcreto) {
    //     return new TipoParametrizado(p_parametrosFormales, p_tipo);
    // }

    /**
     *
     * @param p_parametrosActuales
     * @param p_globales
     * Crea una instancia del tipo resolviendo los parametros de tipo con los argumentos proporcionados.
     */
    instanciaTipo(p_parametrosActuales: ListaParametrosActualesTipo | Tipo[], p_globales: Ambito): TipoConcreto {
        if (this.parametrosFormales.length !== p_parametrosActuales.length) {
            throw new Error(`Número de argumentos de tipo erroneos. Se esperaban ${JSON.stringify(this.parametrosFormales)} pero se obtuvieron ${JSON.stringify(p_parametrosActuales)}`);
        }
        const v_equivalencias = resuelveParametrosTipo(this.parametrosFormales, p_parametrosActuales);
        return this.tipo.resuelve(this.parametrosFormales, v_equivalencias, p_globales);
    }

    get resuelveArgumentos(): ResuelveArgumentos | undefined {
        if (this.tipo instanceof TipoFuncion) {
            return this.tipo.resuelveArgumentos;
        } else {
            throw new Error('Operación no válida; no es una función.');
        }
    }

    esAsignable(p_asignarA: Tipo) {
        return false;
    }
}

/**
 * Clase TipoArray implementado por ITipo
 */
export class TipoArray implements ITipo {
    readonly arrayDe: Tipo | NombreTipo;

    constructor(p_tipoElementos: Tipo | NombreTipo) {
        if (typeof p_tipoElementos !== 'string' &&
            !(p_tipoElementos instanceof TipoArray) &&
            !(p_tipoElementos instanceof TipoPredefinido) &&
            !(p_tipoElementos instanceof TipoObjeto) &&
            !(p_tipoElementos instanceof TipoParametrizado) &&
            !(p_tipoElementos instanceof TipoFuncion)
        ) {
            throw new Error('???????????????????????');
        }
        this.arrayDe = p_tipoElementos;
    }

    /**
     *
     * @param p_parametrosFormales
     * @param p_parametrosActuales
     * @param p_globales
     * Cuando el tipo forme parte de un tipo parametrizado, lo resuelve con los parametros de tipo proporcionados.
     */
    resuelve(p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): TipoConcreto {
        const
            v_tipoElemento = resuelveTipo(this.arrayDe, p_parametrosFormales, p_parametrosActuales, p_globales),
            v_tipo = new TipoArray(v_tipoElemento);
        return v_tipo;
    }

    esAsignable(p_asignarA: Tipo): boolean {
        if (this === p_asignarA || p_asignarA === tipoIndeterminado) {
            return true;
        } else if (p_asignarA instanceof TipoArray) {
            if (typeof this.arrayDe === 'string' || typeof p_asignarA.arrayDe === 'string') {
                throw new Error('Operación no válida; el tipo de elemento no debería ser un nombre de tipo');
            }
            return this.arrayDe.esAsignable(p_asignarA.arrayDe);
        }
        return false;
    }
}


export type TipoConcreto = TipoNulo | TipoPredefinido | TipoArray | TipoObjeto | TipoFuncion;
export type Tipo = TipoParametrizado | TipoConcreto;

type ListaParametrosActualesTipo = (NombreTipo | Tipo)[];

type EquivalenciasTipoParametrizado = Diccionario<Tipo | NombreTipo>;

/**
 *
 * @param p_tipo
 * @param p_parametrosFormales
 * @param p_parametrosActuales
 * @param p_globales
 *
 */
function resuelveTipo(p_tipo: Tipo | NombreTipo, p_parametrosFormales: NombreTipo[], p_parametrosActuales: EquivalenciasTipoParametrizado, p_globales: Ambito): Tipo | NombreTipo {
    if (esNombreTipo(p_tipo)) {
        if (p_parametrosActuales.hasOwnProperty(p_tipo)) {
            const p_parametroActual = p_parametrosActuales[p_tipo];
            if (esNombreTipo(p_parametroActual)) {
                return p_globales.simbolo(p_parametroActual).tipo;
            } else {
                return p_parametroActual;
            }
        } else if (p_globales.existe(p_tipo)) {
            return p_globales.simbolo(p_tipo).tipo;
        } else {
            return p_tipo;
        }
    } else if (p_tipo instanceof TipoParametrizado) {
        return new TipoParametrizado(p_tipo.parametrosFormales, p_tipo.tipo.resuelve(p_parametrosFormales, p_parametrosActuales, p_globales));
    } else {
        return p_tipo.resuelve(p_parametrosFormales, p_parametrosActuales, p_globales);
    }
}

/**
 *
 * @param p_parametrosFormales
 * @param p_parametrosActuales
 * Resuelve los parametros de tipo en la instanción de un tipo parametrizado con los argumentos indicados.
 */
function resuelveParametrosTipo(p_parametrosFormales: NombreTipo[], p_parametrosActuales: ListaParametrosActualesTipo | Tipo[]): EquivalenciasTipoParametrizado {
    // p_parametros = ["T"]
    // p_argumentos = ["number"]
    // v_equivalencias = {"T": "number"}

    const
        v_equivalencias = p_parametrosFormales.reduce((p_anterior, p_nombreTipo, p_indice) => {
            p_anterior[p_nombreTipo] = p_parametrosActuales[p_indice];
            return p_anterior;
        }, {} as EquivalenciasTipoParametrizado);
    return v_equivalencias;
}


tipoIndeterminado.miembros = {
    'to_string': new TipoFuncion([tipoIndeterminado], tipoTexto),
    'to_array': new TipoFuncion([tipoIndeterminado], new TipoArray(tipoIndeterminado))
};

tipoLogico.miembros = {
    '=': new TipoFuncion([tipoLogico, tipoLogico], tipoLogico),
    '<>': new TipoFuncion([tipoLogico, tipoLogico], tipoLogico),
    'no': new TipoFuncion([tipoLogico], tipoLogico)
};

tipoNumero.miembros = {
    '+': new TipoFuncion([tipoNumero, tipoNumero], tipoNumero),
    '-': new TipoFuncion([tipoNumero, tipoNumero], tipoNumero),
    '*': new TipoFuncion([tipoNumero, tipoNumero], tipoNumero),
    '/': new TipoFuncion([tipoNumero, tipoNumero], tipoNumero),
    '<': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    '<=': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    '>': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    '>=': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    '=': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    '<>': new TipoFuncion([tipoNumero, tipoNumero], tipoLogico),
    'negativo': new TipoFuncion([tipoNumero], tipoNumero),
    'to_string': new TipoFuncion([tipoNumero], tipoTexto)
};

tipoTexto.miembros = {
    '+': new TipoFuncion([tipoTexto, tipoTexto], tipoTexto),
    '<': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
    '<=': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
    '>': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
    '>=': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
    '=': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
    '<>': new TipoFuncion([tipoTexto, tipoTexto], tipoLogico),
};

tipoFecha.miembros = {
    // '<': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    // '<=': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    // '>': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    // '>=': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    // '=': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    // '<>': new TipoFuncion(['fecha', 'fecha'], tipoLogico),
    'to_string': new TipoFuncion([tipoFecha], tipoTexto),
};

tipoHora.miembros = {
    '<': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
    '<=': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
    '>': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
    '>=': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
    '=': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
    '<>': new TipoFuncion([tipoHora, tipoHora], tipoLogico),
};

export type Miembros = Diccionario<Tipo | NombreTipo>;
