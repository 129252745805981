import { Component, OnInit, ViewContainerRef, ViewChild, OnDestroy, HostBinding } from '@angular/core';
import { PestanaService } from '../../services/PestanaService';
import { Subscription } from 'rxjs';
import { MenuService } from '../../services/MenuService';
import { PaginaService } from '../../services/PaginaService';
import { ModalService } from '../../services/TarjetaService';

@Component({
    selector: 'app-cabecera',
    templateUrl: './cabecera.component.html'
})
export class CabeceraComponent implements OnInit, OnDestroy {
    constructor(
        private menuService: MenuService,
        private paginaService: PaginaService,
        public pestanaService: PestanaService,
        public modalService: ModalService
    ) {
    }

    private subscripcionPestanaActiva: Subscription;

    @ViewChild('contenedor', { read: ViewContainerRef, static: false }) contenedor: ViewContainerRef;

    ngOnInit() {
        this.subscripcionPestanaActiva = this.pestanaService.subcribeActiva({
            next: p_pestana => {
                if (p_pestana !== null && typeof this.contenedor !== 'undefined') {
                    this.contenedor.insert(p_pestana.hostView);
                }
            }
        });
    }

    minimizaMenu() {
        if (this.menuOculto) {
            this.paginaService.restauraMenu();
        } else if (this.menuMinimizado) {
            this.paginaService.ocultaMenu();
        } else {
            this.paginaService.minimizaMenu();
        }
    }

    get menuMinimizado(): boolean {
        return this.paginaService.menuMinimizado;
    }

    get menuOculto(): boolean {
        return this.paginaService.menuOculto;
    }

    ngOnDestroy() {
        this.subscripcionPestanaActiva.unsubscribe();
    }

    despliegaMenu() {
    }

    agenda() {
        this.menuService.agenda();
    }

    get spinnerActivo(): boolean {
        return this.paginaService.ocupado;
    }
}
