import { Component, HostBinding, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { getLocalization } from './localization';
import { PerfilService } from '../../services/Perfilservice';
import { jqxTextAreaComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea';
import { jqxDateTimeInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput';
import { Variable } from '../../abstracto/acciones/variables';
import { formateaHora, pixelesTexto, pixeles } from '../../abstracto/evotec_comun';
import { ComponenteCampo } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-campo',
    templateUrl: './campo.component.html',
    styleUrls: []
})
export class CampoComponent implements BaseComponenteAngular<ComponenteCampo>, OnInit, OnDestroy, AfterViewInit {
    private static _id = 0;
    id: string;

    constructor(private readonly profileService: PerfilService) {
        this.id = `campo-${CampoComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteCampo,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteCampo | undefined;

    @ViewChild('jqxtextarea', { static: false }) _textArea: jqxTextAreaComponent | undefined;
    @ViewChild('jqxdatetimeinput', { static: false }) _dateTimeInput: jqxDateTimeInputComponent | undefined;

    formatoComponente: any;

    format: string = 'd';
    localization: any;
    @Input() firstDay: number = 0;

    get showCalendarIcon() { return this.formato === 'fecha'; }
    get showTimeIcon() { return this.formato === 'hora'; }

    // Propiedad 'etiqueta'
    private _etiqueta: string | undefined;
    get etiqueta(): string | undefined { return this._etiqueta; }
    set etiqueta(p_valor: string | undefined) { this._etiqueta = p_valor; }

    // Propiedad 'valor'
    private _valor: string | number | Date | undefined;
    get valor(): string | number | Date | undefined {
        // if (this.formato === 'fecha' || this.formato === 'hora') {
        //     if (this._valor !== '') {
        //         this._valor = new Date(this._valor).toString();
        //     }
        // }
        // if (this.formato === 'numero'){
        //     debugger;
        // }
        return this._valor;
    }
    set valor(p_valor: string | number | Date | undefined) {
        // if (this.formato === 'numero'){
        //     debugger;
        // }
        const v_valor = p_valor;

        if (v_valor === this._valor) {
            return;
        }

        this._valor = v_valor;
        if (typeof this.valorCambiado !== 'undefined') {
            this.valorCambiado(v_valor);
        }
    }

    get valorDateTimeInput() {
        if (this.formato === 'fecha') {
            return this._valor;
        } else if (this.formato === 'hora') {
            console.log('valorDateTimeInput');
            if (typeof this._valor === 'string') {
                if (this._valor !== '') {
                    const
                        v_fecha = new Date(0),
                        v_segundos = (this._valor as string)
                            .split(':')
                            .reverse()
                            .reduce((prev, curr, i, array) => prev + parseInt(curr, 10) * Math.pow(60, i + 3 - array.length), 0) +
                            v_fecha.getTimezoneOffset() * 60;
                    v_fecha.setTime(v_segundos * 1000);
                    return v_fecha;
                }
            }
        }
    }

    textAreaActualizaValor() {
        if (typeof this._textArea === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        this.valor = this._textArea.val();
    }

    refrescaValor(p_valor: string | number | Date | undefined) {
        if (p_valor === this._valor) {
            return;
        }
        if (this._textArea && this.formato === 'texto') {
            this._textArea.val(p_valor as string);
        } else if (this._dateTimeInput && (this.formato === 'fecha' || this.formato === 'hora')) {
            this._dateTimeInput.val(p_valor);
        }
        this._valor = p_valor;
    }

    onValueChanged(p_evento: any) {
        if (this.formato === 'fecha') {
            this.valor = p_evento.args.date;
        } else if (this.formato === 'hora') {
            console.log('onValueChanged');
            const v_fecha = p_evento.args.date as Date;
            const v_hhmmss = formateaHora(v_fecha);
            this.valor = v_hhmmss;
        }
    }

    valorCambiado = (p_valor) => { };

    private _modificable: boolean | undefined;

    get modificable(): boolean | undefined { return this._modificable; }
    set modificable(p_valor: boolean | undefined) { this._modificable = p_valor; }

    @Input() get soloLectura(): boolean | null {
        if (this._modificable) {
            return null;
        } else {
            return true;
        }
    }

    private _formato: 'texto' | 'numero' | 'fecha' | 'hora' | undefined;
    get formato(): 'texto' | 'numero' | 'fecha' | 'hora' | undefined {
        return this._formato;
    }
    set formato(p_valor: 'texto' | 'numero' | 'fecha' | 'hora' | undefined) {
        this._formato = p_valor;
    }

    // Propiedad 'visible'
    private _visible = true;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    //@HostBinding('class.oculto')
    get oculto(): boolean {
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado = true;
    get habilitado(): boolean { return this._habilitado; }
    set habilitado(p_valor: boolean) { this._habilitado = p_valor; }

    @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    // Propiedad 'negrita'
    private _negrita: boolean | undefined;
    //@HostBinding('class.negrita')
    get negrita(): boolean | undefined { return this._negrita; }
    set negrita(p_valor: boolean | undefined) { this._negrita = p_valor; }

    // Propiedad 'cursiva'
    private _cursiva: boolean | undefined;
    get cursiva(): boolean | undefined { return this._cursiva; }
    set cursiva(p_valor: boolean | undefined) { this._cursiva = p_valor; }

    // Propiedad 'multilinea'
    private _multilinea: boolean | undefined;
    get multilinea(): boolean | undefined { return this._multilinea; }
    set multilinea(p_valor: boolean | undefined) { this._multilinea = p_valor; }

    // Propiedad '_lineas'
    private _lineas: number | undefined;
    get lineas(): number | undefined { return this._lineas; }
    set lineas(p_valor: number | undefined) { this._lineas = p_valor; }

    // Propiedad 'ancho'
    private _ancho: string | undefined;
    get ancho(): string | undefined { return this._ancho; }
    set ancho(p_valor: string | undefined) { this._ancho = p_valor; }

    get pixelesAncho(): string | number | undefined {
        if (this.formato === 'texto' && typeof this._ancho === 'undefined') {
            return '100%';
        }

        let v_pixelesEtiqueta: number;
        if (typeof this._etiqueta === 'string') {
            v_pixelesEtiqueta = pixelesTexto(this._etiqueta) * 1.2;
        } else {
            v_pixelesEtiqueta = 0;
        }
        let v_pixeles: number;
        const v_ancho = typeof this._ancho === 'undefined' ? '10car' : this._ancho;

        v_pixeles = pixeles(v_ancho);
        return Math.max(Math.max(v_pixelesEtiqueta, v_pixeles), 100);

    }

    // onValueChanged(event: any): void {
    //     const date: Date = event.args.date;
    //     if (date) {
    //         switch (this.formato) {
    //             case 'fecha':
    //                 this._valor = date.toLocaleDateString();
    //                 this.valorCambiado(this._valor);
    //                 break;
    //             case 'hora':
    //                 this._valor = date.toLocaleTimeString();
    //                 this.valorCambiado(this._valor);
    //                 break;
    //         }
    //     }
    // }

    establecerFormatoFechaHora() {
        this.firstDay = this.localization.firstDay;

        switch (this.formato) {
            case 'fecha':
                this.format = this.localization.patterns.d;
                // this.showTimeIcon = false;
                // this.showCalendarIcon = true;
                break;
            case 'hora':
                this.format = this.localization.patterns.T;
                // this.showTimeIcon = true;
                // this.showCalendarIcon = false;
                break;
            //   case 'datetime':
            //     this.format =  this.localization.patterns.F;
            //     this.showTimeIcon = true;
            //     this.showCalendarIcon = true;
            //     break;
            default:
                this.format = this.localization.patterns.d;
                // this.showTimeIcon = false;
                // this.showCalendarIcon = true;
                break;
        }
    }

    // Controlamos el foco del campo
    // Si el foco está en el campo añadimos clase 'focused'
    //@HostBinding('class.focused') 
    foco: boolean = false;

    focusFunction() {
        this.foco = true;
    }

    focusOutFunction() {
        if (this.valor === '' || null) this.foco = false;
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        const v_clases = ['campo'].concat(this.componente.estilos);
        if (this.oculto) { v_clases.push('oculto'); }
        if (this.negrita) { v_clases.push('negrita'); }
        if (this.cursiva) { v_clases.push('cursiva'); }
        if (this.foco) { v_clases.push('focused'); }
        //if (typeof this.ancho !== 'undefined') { v_clases.push(`ancho-${this.ancho}`); }

        return v_clases.join(' ');
    }

    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);

        this._multilinea = this.componente.multilinea;
        if (this._multilinea) {
            this._lineas = Math.max(5, this.componente.lineas);
        } else {
            this._lineas = this.componente.lineas;
        }

        // Inicializar propiedades
        this.formatoComponente = this.formato;
        // this.valorComponente = this.valor;
        if (this.valor !== null) this.foco = true;
        // Cargar perfil para obtener la localización
        // y establecer los formatos de la fecha y la hora
        setTimeout(_ => {
            const perfil = this.profileService.activo;
            this.localization = getLocalization(perfil.cultureInfo);
            this.establecerFormatoFechaHora();
        });

    }

    ngAfterViewInit(): void {
        if (typeof this._textArea !== 'undefined') {
            if (typeof this._valor === 'string') {
                this._textArea.val(this._valor);
            } else {
                this._textArea.val(null);
            }
        }
        if (typeof this._dateTimeInput !== 'undefined') {
            if (this.formato === 'fecha' || this.formato === 'hora') {
                this._dateTimeInput.val(this.valorDateTimeInput);
            } else {
                this._dateTimeInput.val(null);
            }
        }
    }

    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }
}
// REGISTRY.set('campo', CampoComponent);
