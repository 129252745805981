import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { Variable } from '../../abstracto/acciones/variables';
import { igual, pixeles } from '../../abstracto/evotec_comun';
import { ComponenteTabla } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-tabla',
    templateUrl: './tabla.component.html',
    styleUrls: []
})
export class TablaComponent implements BaseComponenteAngular<ComponenteTabla>, OnInit {
    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteTabla,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo | undefined;
    instanciaModelo: InstanciaModelo | undefined;
    modeloInvocante: Variable | undefined;
    fila: number | undefined;

    componente: ComponenteTabla | undefined;

    // Propiedad 'etiqueta'
    private _etiqueta: string | undefined;
    get etiqueta(): string | undefined { return this._etiqueta; }
    set etiqueta(p_valor: string | undefined) { this._etiqueta = p_valor; }

    // Propiedad 'seleccionado'
    private _seleccionado: any = null;
    get seleccionado(): any { return this._seleccionado; }
    set seleccionado(p_valor: any) {
        if (typeof this._grid === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        if (igual(p_valor, this._seleccionado)) {
            return;
        }

        this._seleccionado = p_valor;

        if (typeof this._registros !== 'undefined') {
            const v_indice = this._registros.findIndex(p_registro => igual(p_registro, this._seleccionado));
            if (v_indice === -1) {
                if (this._grid.getselectedrowindexes().length > 0) {
                    this._grid.clearselection();
                }
            } else {
                const v_indiceActual = this._grid.getselectedrowindex();
                if (v_indiceActual !== v_indice) {
                    this._grid.selectrow(v_indice);
                }
            }
        }

        if (typeof this.seleccionadoCambiado !== 'undefined') {
            this.seleccionadoCambiado(this.seleccionado);
        }
    }

    seleccionadoCambiado: ((p_valor: any) => void) | undefined;

    alSeleccionarFila(p_evento: any) {
        if (typeof this._registros === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        const v_registro = this._registros[p_evento.args.rowindex];
        if (igual(v_registro, this.seleccionado)) {
            // this.seleccionado = null;
            return;
        }
        this.seleccionado = v_registro;
    }

    alPulsarFila(p_evento: any) {
        // const v_indiceActual = this._grid.getselectedrowindex();

        // if (p_evento.args.rowindex === v_indiceActual) {
        //     this._seleccionado = null;
        //     this._grid.clearselection();
        //     this._grid.unselectrow(v_indiceActual);
        // }
    }

    get modoSeleccion(): string {
        if (typeof this.seleccionadoCambiado === 'undefined') {
            return 'none';
        } else {
            return 'singlerow';
        }
    }

    get habilitaResalte(): boolean {
        return typeof this.seleccionadoCambiado !== 'undefined';
    }

    private _modificable: boolean | undefined;
    get modificable(): boolean {
        if (typeof this._modificable === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._modificable;
    }
    set modificable(p_valor: boolean) { this._modificable = p_valor; }

    get soloLectura(): boolean | null {
        if (this._modificable) {
            return null;
        } else {
            return true;
        }
    }

    private _formato: string | undefined;
    get formato(): string {
        if (typeof this._formato !== 'undefined') {
            return this._formato;
        } else {
            return '';
        }
    }
    set formato(p_valor: string) { this._formato = p_valor; }

    // Propiedad 'registros'
    private _registros: object[] | undefined;
    get registros(): object[] {
        if (typeof this._registros === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._registros;
    }
    set registros(p_registros: object[]) {
        if (typeof this._grid === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_seleccionado = this.seleccionado;
        if (this._seleccionado && this._grid.selectedrowindex() > -1) {
            this.seleccionado = null;
            this._grid.clearselection();
        }

        if (Array.isArray(this._registros)) {
            if (typeof p_registros === 'undefined') {
                this._registros.splice(0, this._registros.length);
            } else {
                this._registros.splice(0, this._registros.length, ...p_registros);
            }
        } else {
            this._registros = p_registros;
        }

        this.seleccionado = v_seleccionado;

        this._grid.updatebounddata('cells');
    }

    // Propiedad 'columnas'
    private _columnas: any[] | undefined;
    get columnas(): any[] {
        if (typeof this._columnas === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return this._columnas;
    }
    set columnas(p_valor: any[]) {
        // Probar formato fecha que viene de la propiedad formato en el generador Angular
        this._columnas = p_valor;
        this.columnasGrid = this._columnas.map(p_columna => {
            const
                v_descriptorColumna = this.descriptorColumna[p_columna.tipo],
                v_formatoCelda = typeof v_descriptorColumna !== 'undefined' ? v_descriptorColumna.formato : undefined,
                v_anchoColumna = typeof v_descriptorColumna !== 'undefined' ? v_descriptorColumna.ancho : undefined,
                v_columna = {
                    text: p_columna.etiqueta,
                    datafield: p_columna.propiedadModelo,
                };
            if (typeof v_formatoCelda !== 'undefined') {
                v_columna['cellsformat'] = v_formatoCelda;
            }
            if (typeof v_anchoColumna !== 'undefined') {
                v_columna['width'] = v_anchoColumna;
            }

            // if (typeof p_columna.alNavegar === 'function') {
            //     return {
            //         text: p_columna.etiqueta,
            //         datafield: p_columna.propiedadModelo,
            //         cellsformat: v_formatoCelda,
            //         //width: v_anchoColumna,

            //         // cellsrenderer: function (row: any, columnfield: any, value: any, defaulthtml: any, columnproperties: any) {
            //         //     // const v_elementos = new DOMParser().parseFromString(defaulthtml, 'text/xml');
            //         //     // if (value < 20) {
            //         //     //     return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #0000ff;">' + value + '</span>';
            //         //     // } else {
            //         //     //     return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
            //         //     // }

            //         //     return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #0000ff;">' + value + '</span>'
            //         // }
            //     };
            // }

            return v_columna;
        });
        this.registros = this._registros;
    }

    private readonly descriptorColumna = {
        'fecha': {
            tipo: 'date',
            formato: 'dd/MM/yyyy',
            ancho: pixeles(`${'dd/MM/yyyy'.length}car`)
        },
    };

    actualizaEtiqueta(p_indice: number, p_etiqueta: string) {
        if (typeof this._grid === 'undefined' || typeof this.columnasGrid === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_dataField = this.columnasGrid[p_indice].datafield;

        this.columnasGrid[p_indice].text = p_etiqueta;
        this._grid.setcolumnproperty(v_dataField, 'text', p_etiqueta);
    }

    columnasGrid: any[] | undefined;

    // Propiedad 'visible'
    private _visible: boolean | undefined;
    get visible(): boolean | undefined { return this._visible; }
    set visible(p_valor: boolean | undefined) { this._visible = p_valor; }

    @HostBinding('class.oculto')
    get oculto(): boolean {
        if (typeof this._visible === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        return !this._visible;
    }

    // Propiedad 'habilitado'
    private _habilitado: boolean | undefined;
    get habilitado(): boolean | undefined { return this._habilitado; }
    set habilitado(p_valor: boolean | undefined) { this._habilitado = p_valor; }

    @HostBinding('attr.disabled')
    get deshabilitado(): boolean | null {
        if (this._habilitado) {
            return null;
        } else {
            return true;
        }
    }

    // Propiedad 'numeroPagina'
    private _numeroPagina: number | undefined;
    get numeroPagina(): number | undefined {
        return this._numeroPagina;
    }
    set numeroPagina(p_valor: number | undefined) {
        if (p_valor === this._numeroPagina) {
            return;
        }

        this._numeroPagina = p_valor;
        if (typeof this.numeroPaginaCambiada !== 'undefined') {
            this.numeroPaginaCambiada(p_valor);
        }
    }

    numeroPaginaCambiada = (p_valor: any) => { };

    // Propiedad 'registrosPorPagina'
    private _registrosPorPagina: number | undefined;
    get registrosPorPagina(): number | undefined {
        // if (typeof this._registrosPorPagina === 'undefined') {
        //     throw new Error('Error interno; el componente no se ha inicializado aún.');
        // }
        return this._registrosPorPagina;
    }
    set registrosPorPagina(p_valor: number | undefined) {
        if (p_valor === this._registrosPorPagina) {
            return;
        }
        this._registrosPorPagina = p_valor;
        if (typeof this.registrosPorPaginaCambiado !== 'undefined') {
            this.registrosPorPaginaCambiado(p_valor);
        }
    }

    registrosPorPaginaCambiado = (p_valor: any) => { };

    readonly opcionesRegistrosPorPagina = [2, 4, 5, 6, 8, 10, 20, 50];

    get tienePaginacion(): boolean {
        return typeof this.numeroPagina !== 'undefined' && typeof this.registrosPorPagina !== 'undefined';
    }

    @ViewChild('jqxgrid', { static: true }) _grid: jqxGridComponent | undefined;
    private _origenDatos: any;
    private _adaptador: any;
    get adaptador(): any { return this._adaptador; }

    // alCambiarNumeroPagina(event: any): void {
    //     this.numeroPagina = event.args.pagenum + 1;
    // }

    // alCambiarRegistrosPorPagina(event: any): void {
    //     if (typeof this._grid === 'undefined') {
    //         throw new Error('Error interno; el componente no se ha inicializado aún.');
    //     }
    //     this._grid.gotopage(event.args.pagenum);
    //     this.registrosPorPagina = event.args.pagesize;
    // }

    dobleClicSobreCelda(p_evento: any): void {
        const
            v_indiceColumna: number = p_evento.args.columnindex,
            v_indiceFila: number = p_evento.args.rowindex,
            v_clic = this.columnas[v_indiceColumna].alNavegar;
        if (typeof v_clic === 'function') {
            v_clic(v_indiceFila);
        }
    }

    paginaAnterior(p_evento: any): void {
        const v_numeroPagina = this.numeroPagina - 1;
        this.numeroPagina = Math.max(1, v_numeroPagina);
    }

    paginaSiguiente(p_evento: any): void {
        this.numeroPagina = this.numeroPagina + 1;
    }

    ngOnInit(): void {
        if (typeof this._columnas === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }
        this._origenDatos = {
            datatype: 'array',
            localdata: this.registros,
            datafields: this._columnas.map(p_columna => {
                const v_descriptorColumna = this.descriptorColumna[p_columna.tipo];
                if (typeof v_descriptorColumna === 'undefined') {
                    return { name: p_columna.propiedadModelo };
                } else {
                    return {
                        name: p_columna.propiedadModelo,
                        type: v_descriptorColumna.tipo
                    };
                }
            })
        };

        this._adaptador = new jqx.dataAdapter(this._origenDatos);
    }
}
