import { Diccionario, MiembrosDe } from '../evotec_comun';
import { Tipo } from '../acciones/tipos';

export interface Simbolo {
    readonly tipo: Tipo;
    readonly valor?: any;
}

type NodoSimbolo = MiembrosDe<Simbolo> & { readonly ranura: number };

/**
 * Clase Ambito
 */
export class Ambito {
    private readonly simbolos: Diccionario<NodoSimbolo>;
    numeroSimbolos: number;

    constructor(p_simbolos: Diccionario<Simbolo> = {}) {
        this.simbolos = Object.getOwnPropertyNames(p_simbolos)
            .reduce((p, p_nombre, p_indice) => {
                p[p_nombre] = { ...p_simbolos[p_nombre], ranura: p_indice };
                return p;
            }, {} as Diccionario<NodoSimbolo>);
        this.numeroSimbolos = Object.getOwnPropertyNames(this.simbolos).length;
    }

    /**
     * 
     * @param p_nombre
     * Busca un nombre en el ambito
     */
    simbolo(p_nombre: string): NodoSimbolo | undefined {
        return this.simbolos[p_nombre];
    }

    /**
     * 
     * @param p_nombre
     * Comprueba que la propiedad exista
     */
    existe(p_nombre: string): boolean {
        return this.simbolos.hasOwnProperty(p_nombre);
    }

    /**
     * 
     * @param p_nombre 
     * @param p_tipo 
     * @param p_ranuraBase
     * Añade un nombre al ámbito y devuelve el número de ranura en el registro de activación 
     */
    creaSimbolo(p_nombre: string, p_tipo: Tipo, p_valor: any): number {
        const v_ranura = this.numeroSimbolos++;
        this.simbolos[p_nombre] = {
            tipo: p_tipo,
            valor: p_valor,
            ranura: v_ranura
        };
        return v_ranura;
    }

    ranuras() {
        const
            v_array = Object.getOwnPropertyNames(this.simbolos)
                .map(p_nombre => ({ nombre: p_nombre, ...this.simbolos[p_nombre] })),
            v_arrayOrdenado = v_array.sort((a, b) => {
                if (a.ranura > b.ranura) {
                    return 1;
                } else if (a.ranura < b.ranura) {
                    return -1;
                } else {
                    return 0;
                }
            });

        return v_arrayOrdenado;
    }
}

// Uno de los nodos en una lista de ambitos.
interface NodoAmbito {
    anterior?: NodoAmbito;
    actual: Ambito;
}

/**
 * Clase contexto
 */
export class TablaSimbolos {
    numeroRanuras: number;
    private ambito: NodoAmbito;

    constructor(p_ambitoGlobal: Ambito) {
        this.numeroRanuras = p_ambitoGlobal.numeroSimbolos;
        this.ambito = {
            actual: p_ambitoGlobal
        };
        this.creaAmbito();
    }
    /**
     * 
     * @param p_nombre 
     * Busca un nombre en el contexto. La busqueda se lleva a cabo por toda la cadena de ámbitos del contexto.
     * Si no se encuentra el nombre el resultado es "undefined"
     */
    simbolo(p_nombre: string): NodoSimbolo | undefined {
        let v_ambito: NodoAmbito | undefined = this.ambito;
        while (v_ambito) {
            const v_valor = v_ambito.actual.simbolo(p_nombre);
            if (typeof v_valor !== 'undefined') {
                return v_valor;
            }
            v_ambito = v_ambito.anterior;
        }
        // throw new Error('El símbolo no existe');
    }

    /**
     * 
     * @param p_nombre 
     * @param p_tipo 
     * Añade un nombre al ámbito actual y devuelve el número de ranura en el registro de activación.
     * En caso de que el nombre exista en el ambito actual se produce un error.
     */
    creaSimbolo(p_nombre: string, p_tipo: Tipo, p_valor?: any): number {
        const v_ambitoActual = this.ambito.actual;

        if (typeof v_ambitoActual.simbolo(p_nombre) !== 'undefined') {
            throw new Error(`Ya existe un símbolo con nombre ${p_nombre} en este ámbito`);
        }

        const v_ranura = v_ambitoActual.creaSimbolo(p_nombre, p_tipo, p_valor);
        this.numeroRanuras = Math.max(v_ranura + 1, this.numeroRanuras);
        return v_ranura;
    }

    /**
     * Crea un nuevo ámbito en el contexto y lo devuelve
     */
    creaAmbito(): Ambito {
        const v_nuevo = new Ambito();
        this.ambito = {
            anterior: this.ambito,
            actual: v_nuevo
        };
        return v_nuevo;
    }

    /**
     * Elimina el ámbito actual y vuelve al ámbito anterior.
     */
    eliminaAmbito(): void {
        const v_nuevo = this.ambito.anterior;
        if (typeof v_nuevo === 'undefined') {
            throw new Error('Operación no válida. No se puede eliminar el ambito global.');
        }

        const v_actual = this.ambito.actual;
        this.ambito = {
            anterior: v_nuevo.anterior,
            actual: v_nuevo.actual
        };
    }
}
