import { Enlace, Modelo, Cambio, cambios, InstanciaModelo, InstanciaModeloValoresObjeto } from './ejecucion/modelo';
import { tiposPredefinidos } from './especificacion/tipos';
import { Diccionario, comoJson, igual } from './evotec_comun';
import { DescriptorCalculo } from './especificacion/componentes';


// ---------------------------------------------------------------------------------------------
// modelo --------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------

// REVISAR exports

type TransformacionValor = (p_valor: any, p_transformacion: DescriptorCalculo) => any;
type TransformacionesValor = Diccionario<TransformacionValor>;

interface TransformacionComparacion extends DescriptorCalculo {
    valor: any;
}

interface TransformacionFormatea extends DescriptorCalculo {
    formato: string;
}

class TransformacionesEnlaces {
    static mayusculas(p_valor: string): string {
        if (typeof p_valor !== 'string') {
            console.warn(`${p_valor} no es un valor de tipo 'string'.`);
            return '';
        }
        return p_valor.toUpperCase();
    }

    static minusculas(p_valor: string): string {
        if (typeof p_valor !== 'string') {
            console.warn(`${p_valor} no es un valor de tipo 'string'.`);
            return '';
        }
        return p_valor.toLowerCase();
    }

    static igual(p_valor: any, p_otroValor: any): boolean {
        return p_valor === p_otroValor;
    }

    static esComparacion(p_transformacion: DescriptorCalculo): p_transformacion is TransformacionFormatea {
        return typeof p_transformacion === 'object' && p_transformacion.hasOwnProperty('valor');
    }

    static formatea(p_valor: any, p_formato: string): string {
        const v_argumentos = [p_valor];
        return p_formato.replace(/{(\d+)}/g, (p_coincidencia, p_indice) => typeof v_argumentos[p_indice] !== 'undefined' ? v_argumentos[p_indice] : p_coincidencia);
    }

    static esTransformacionFormatea(p_transformacion: DescriptorCalculo): p_transformacion is TransformacionFormatea {
        return typeof p_transformacion === 'object' && typeof (p_transformacion.formato) === 'string';
    }
}

const v_transformacionesEnlaces = <TransformacionesValor>{
    // 'ninguna': p_valor => p_valor,
    'mayusculas': TransformacionesEnlaces.mayusculas,
    'minusculas': TransformacionesEnlaces.minusculas,
    'S_es_true': p_valor => p_valor === 'S',
    'N_es_true': p_valor => p_valor === 'N',
    'true_es_S': p_valor => p_valor ? 'S' : 'N',
    'true_es_N': p_valor => p_valor ? 'N' : 'S',
    'vacio_es_true': p_valor => !!p_valor,
    'vacio_es_false': p_valor => !p_valor,
    'null_es_true': p_valor => p_valor === null,
    'null_es_false': p_valor => p_valor !== null,
    'no': p_valor => !p_valor,
    'igual': (p_valor, p_transformacion) => p_valor === p_transformacion.valor,
    'distinto': (p_valor, p_transformacion) => p_valor !== p_transformacion.valor,
    'mayor': (p_valor, p_transformacion) => p_valor > p_transformacion.valor,
    'mayorIgual': (p_valor, p_transformacion) => p_valor >= p_transformacion.valor,
    'menor': (p_valor, p_transformacion) => p_valor < p_transformacion.valor,
    'menorIgual': (p_valor, p_transformacion) => p_valor <= p_transformacion.valor,
    'equivalencia': (p_valor, p_transformacion) => p_transformacion.tabla.filter((p: { clave: any, valor: any }) => p.clave === p_valor)[0].valor,
    'formatea': (p_valor, p_transformacion) => {
        if (TransformacionesEnlaces.esTransformacionFormatea(p_transformacion)) {
            return TransformacionesEnlaces.formatea(p_valor, p_transformacion.formato);
        } else {
            console.debug(`${comoJson(p_transformacion)} no es una transformación 'formatea' válida.`);
            return '';
        }
    }
}

export function transformaValor(p_valor: any, p_descriptorTransformacion: DescriptorCalculo | undefined): any {
    if (typeof p_descriptorTransformacion === 'undefined') {
        return p_valor;
    }

    // REVISA no debería ser el enlace quien determinase que ocurre en este caso??
    if (typeof p_valor === 'undefined') {
        p_valor = null;
    }

    if (typeof p_descriptorTransformacion.operacion === 'undefined') {
        return p_valor;
    }

    let v_transforma = v_transformacionesEnlaces[p_descriptorTransformacion.operacion];
    if (typeof v_transforma === 'undefined') {
        v_transforma = p_valor => p_valor;
    }

    const v_valor = v_transforma(p_valor, p_descriptorTransformacion);
    console.debug(`Aplicando transformación ${comoJson(p_descriptorTransformacion)}: <${p_valor}> pasa a ser <${v_valor}>.`);

    return v_valor;
}

// devuelve una función que actualizará el modelo y registrará los cambios realizados a raiz de un cambio llevado a cabo en la vista
export function actualizadorModelo(
    p_definicionModelo: Modelo,
    p_instanciaModelo: InstanciaModelo,
    p_enlace: Enlace,
    p_valorComponente: () => any,
    p_fila: number,
    p_contextoLlamada: any
): ((p_valor: any) => void) | ((p_valorComponente: any) => void) {

    if (typeof p_contextoLlamada.pestana !== 'undefined') {
        console.log('!undefined');
    } else {
        console.log('undefined');
    }

    if (typeof p_enlace === 'undefined') {
        return () => { };
    }

    const v_contextoLlamada = p_contextoLlamada;

    const v_actualizador = (p_valorComponente: any) => {
        const
            v_propiedad = p_definicionModelo.damePropiedad(p_enlace.propiedad),
            v_nombreTipo = v_propiedad.tipo,
            v_tipo = tiposPredefinidos[v_nombreTipo];
        let v_valor: any;
        if (typeof v_tipo !== 'undefined') {
            v_valor = v_tipo.desdeTexto(p_valorComponente);
        } else {
            v_valor = p_valorComponente;
        }

        const
            v_valores = p_instanciaModelo.valores as InstanciaModeloValoresObjeto,
            v_nuevoValor = transformaValor(v_valor, p_enlace.actualizaModelo),
            v_valorAnterior = v_valores[p_enlace.propiedad];

        if (!igual(v_nuevoValor, v_valorAnterior)) {
            v_valores[p_enlace.propiedad] = v_nuevoValor;

            const v_cambio = new Cambio(p_definicionModelo, p_instanciaModelo, p_enlace.propiedad, p_fila, v_nuevoValor, v_valorAnterior, v_contextoLlamada);
            cambios.push(v_cambio);

            cambios.aplica();
        }
    };

    if (typeof p_valorComponente === 'undefined') {
        return v_actualizador;
    } else {
        return p_valor => v_actualizador(p_valorComponente());
    }
}
