import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CabeceraComponent } from './cabecera.component';
import { PerfilComponent } from './perfil/perfil.component';
import { OpcionMenuComponent } from '../menu/opcionMenu.component';
import { JqwidgetsModule } from '../../../shared/jqwidgets/jqwidgets.module';

export const CONTAINERS = [
    CabeceraComponent,
    PerfilComponent,
    OpcionMenuComponent,
];

export const EXPORT_CONTAINERS = [
    CabeceraComponent,
    PerfilComponent,
    OpcionMenuComponent,
];

export const EXPORT_PROVIDERS = [
    OpcionMenuComponent,
];

@NgModule({
    imports: [
        CommonModule,
        JqwidgetsModule,
    ],
    declarations: EXPORT_CONTAINERS,
    exports: EXPORT_CONTAINERS,
    providers: EXPORT_PROVIDERS,
    entryComponents: CONTAINERS
})
export class CabeceraModule { }
