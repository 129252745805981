import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';

import { jqxMenuComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxmenu';
import { MenuService } from '../../services/MenuService';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PerfilService } from '../../services/Perfilservice';
import { PaginaService } from '../../services/PaginaService';
import { environment } from '../../../../environments/environment';
import { RespuestaApi } from '../../abstracto/acciones/evotec_acciones';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {
    innerWidth: number;

    // @HostListener('window:resize')
    onResize() {
        this.innerWidth = window.innerWidth;
        this.innerWidth < 1200 ? this.jqxmenu.minimize() : this.jqxmenu.restore();
    }

    @ViewChild('menuElements', { static: false}) menuElements: ElementRef;
    @ViewChild('jqxmenu', { static: false}) jqxmenu: jqxMenuComponent;

    constructor(
        private http: HttpClient,
        public perfilService: PerfilService,
        private menuService: MenuService,
        private paginaService: PaginaService) {
    }

    menu: any[];

    setMenuSubscription: any;

    ngOnInit() {
        const
            v_url: string = environment.apiUrl + '/evt_menu',
            v_params = new HttpParams()
                .set('emp_cod', this.perfilService.activo.emp_cod)
                .set('empgrupo_cod', this.perfilService.activo.empgrupo_cod)
                .set('opc', '-prefijo-tecla-acceso');

        this.setMenuSubscription = this.http.get<RespuestaApi<any[]>>(v_url, { params: v_params }).subscribe(p_respuesta => {
            if (p_respuesta.Ok) {
                const self = this;

                p_respuesta.Objeto
                    .map(p_opcion => self.menuService.creaNodo(p_opcion, true, true, self.closeMenu))
                    .forEach(this.menuService.insertaNodoEn(this.menuElements.nativeElement));

                this.jqxmenu.createComponent({});

                // si entramos desde una tablet, el menú por defecto comprimido. (Sólo se comprueba al entrar la primera vez, no al cambiar de tamaño la ventana)
                if (window.innerWidth <= 512) {
                    this.paginaService.ocultaMenu();
                } else if (window.innerWidth <= 1024) {
                    this.paginaService.minimizaMenu();
                } else {
                    this.paginaService.restauraMenu();
                }

            }
        });
    }

    ngOnDestroy() {
        this.setMenuSubscription.unsubscribe();
        if (this.jqxmenu.widgetObject) {
            this.jqxmenu.destroy();
        }
    }

    itemclick(p_evento) {
        const usmenu_cod = p_evento.target.getAttribute('data-usmenu_cod');

        if (usmenu_cod === '090101') {
            this.menuService.ejecuta({ programa: 'inicio' });
        } else if (usmenu_cod !== null) {
            this.menuService.abreOpcion(usmenu_cod);
        }

    }

    closeMenu() {
        const nl = document.querySelectorAll('.jqx-fill-state-pressed');

        const arr = [];
        for (let i = nl.length; i--; arr.unshift(nl[i])) { }

        arr.forEach(element => {
            this.jqxmenu.closeItem(element.id);
        });
    }
}
