import { Component, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Spyro';

    constructor(
        public readonly viewContainerRef: ViewContainerRef) {
      }
}
