import { Injectable } from '@angular/core';
import { BehaviorSubject, PartialObserver, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfirmacionService {
    private _mensaje = new BehaviorSubject<string | null>(null);

    muestra(p_mensaje: string) {
        this._mensaje.next(p_mensaje);
    }

    subcribeMensaje(p_observador: PartialObserver<string | null>) {
        return this._mensaje.subscribe(p_observador);
    }
}
