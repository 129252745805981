import { Diccionario, formateaFecha, textoEsFecha } from '../evotec_comun';

interface TipoPredefinido {
    valorVacio: any;
    instancia(p_valor: any): any;
    desdeTexto(p_texto: any): any;
    esAsignable(p_valor: any): boolean;
}

type TiposPredefinidos = Diccionario<TipoPredefinido>;

const
    tipoPredefinidoFecha: TipoPredefinido = {
        // instancia: p_texto => {
        //     if (typeof p_texto === 'undefined') {
        //         return p_texto;
        //     } else if (typeof p_texto === 'string') {
        //         if (p_texto !== '') {
        //             const v_fecha = new Date(p_texto);
        //             return v_fecha;
        //         }
        //     } else if (p_texto instanceof Date) {
        //         return p_texto;
        //     } else {
        //         throw new Error('???');
        //     }
        // },
        // instancia: p_texto => p_texto,
        instancia: p_texto => {
            if ((typeof p_texto === 'string' && p_texto === '') || p_texto === null) {
                return null;
            }
            return formateaFecha(p_texto, { hora: true, segundos: true });
        },
        // desdeTexto: p_texto => {
        //     if (typeof p_texto === 'undefined') {
        //         return p_texto;
        //     } else if (p_texto !== '') {
        //         const v_fecha = new Date(p_texto);
        //         return v_fecha;
        //     }
        // },
        desdeTexto: p_texto => p_texto,
        valorVacio: '',
        esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || (typeof p_valor === 'string' && textoEsFecha(p_valor)) || p_valor instanceof Date
        // esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || typeof p_valor === 'string'
    },
    tipoPredefinidoHora: TipoPredefinido = {
        // instancia: p_texto => {
        //     if (p_texto) {
        //         const v_fecha = new Date(1971, 1, 1);
        //         v_fecha.setTime(p_texto.split(':').reverse().reduce((prev, curr, i, array) => prev + curr * Math.pow(60, i + 3 - array.length), 0));
        //         return v_fecha;
        //     }
        // },
        instancia: p_texto => p_texto,
        // desdeTexto: p_texto => {
        //     const v_fecha = new Date(1971, 1, 1);
        //     v_fecha.setTime(p_texto.split(':').reverse().reduce((prev, curr, i, array) => prev + curr * Math.pow(60, i + 3 - array.length), 0));
        //     return v_fecha;
        // },
        desdeTexto: p_texto => p_texto,
        valorVacio: '',
        // esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || typeof p_valor === 'string' || p_valor instanceof Date
        esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || typeof p_valor === 'string'
    },
    tipoPredefinidoNumero: TipoPredefinido = {
        instancia: p_numero => p_numero,
        desdeTexto: p_texto => {
            if (typeof p_texto === 'string' && p_texto === '') {
                return null;
            }
            const v_resultado = p_texto * 1;
            if (isNaN(v_resultado)) {
                return 0;
            }
            return v_resultado;
        },
        valorVacio: 0,
        esAsignable: p_valor => p_valor === null || (typeof p_valor === 'string' && p_valor === '') || typeof p_valor === 'undefined' || typeof p_valor === 'number'
    },
    tipoPredefinidoTexto: TipoPredefinido = {
        instancia: p_texto => p_texto,
        desdeTexto: p_texto => p_texto,
        valorVacio: '',
        esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || typeof p_valor === 'string'
    },
    tipoPredefinidoArray: TipoPredefinido = {
        instancia: p_array => p_array,
        desdeTexto: p_texto => { throw new Error('Operación no válida'); },
        valorVacio: [],
        esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || Array.isArray(p_valor)
    },
    tipoPredefinidoLogico: TipoPredefinido = {
        instancia: p_logico => p_logico,
        desdeTexto: p_texto => p_texto === 'true',
        valorVacio: false,
        esAsignable: p_valor => p_valor === null || typeof p_valor === 'undefined' || typeof p_valor === 'boolean'
    };

export const tiposPredefinidos: TiposPredefinidos = {
    fecha: tipoPredefinidoFecha,
    date: tipoPredefinidoFecha,
    hora: tipoPredefinidoHora,
    time: tipoPredefinidoHora,
    numero: tipoPredefinidoNumero,
    number: tipoPredefinidoNumero,
    texto: tipoPredefinidoTexto,
    string: tipoPredefinidoTexto,
    array: tipoPredefinidoArray,
    logico: tipoPredefinidoLogico,
};

export function esTipoPredefinido(p_nombre: string): boolean {
    return typeof tiposPredefinidos[p_nombre] !== 'undefined';
}