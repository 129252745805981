import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Variable } from '../../abstracto/acciones/variables';
import { ComponenteEtiqueta } from '../../abstracto/ejecucion/componentes';
import { Modelo, InstanciaModelo, InstanciaModeloValores } from '../../abstracto/ejecucion/modelo';
import { BaseComponenteAngular } from '../UtilidadesComponentes';

@Component({
    selector: 'app-etiqueta',
    templateUrl: './etiqueta.component.html',
    styleUrls: []
})
export class EtiquetaComponent implements BaseComponenteAngular<ComponenteEtiqueta>, OnInit, OnDestroy {
    ngOnInit(): void {
        console.warn(`Creando ${this.id}`);
    }
    ngOnDestroy(): void {
        console.warn(`Destruyendo ${this.id}`);
    }

    private static _id = 0;
    id: string;

    constructor() {
        this.id = `etiqueta-${EtiquetaComponent._id++}`;
    }

    inicializa(
        p_definicionModelo: Modelo,
        p_instanciaModelo: InstanciaModelo<InstanciaModeloValores>,
        p_fila: number,
        p_componente: ComponenteEtiqueta,
        p_modeloInvocante: Variable): void {

        this.definicionModelo = p_definicionModelo;
        this.instanciaModelo = p_instanciaModelo;
        this.fila = p_fila;
        this.componente = p_componente;
        this.modeloInvocante = p_modeloInvocante;
    }

    definicionModelo: Modelo;
    instanciaModelo: InstanciaModelo;
    modeloInvocante: Variable;
    fila: number;

    componente: ComponenteEtiqueta;

    // Propiedad 'valor'
    private _valor: string;
    get valor(): string { return this._valor; }
    set valor(p_valor: string) { this._valor = p_valor; }

    // Propiedad 'visible'
    private _visible: boolean;
    get visible(): boolean { return this._visible; }
    set visible(p_valor: boolean) { this._visible = p_valor; }

    get oculto(): boolean {
        return !this._visible;
    }

    public get esHtml(): boolean {
        const v_resultado = this.componente.estilos.some(p_estilo => p_estilo === 'html');
        return v_resultado;
    }

    // Estilos
    @HostBinding('class')
    get estilos() {
        if (typeof this.componente === 'undefined') {
            throw new Error('Error interno; el componente no se ha inicializado aún.');
        }

        const v_clases = ['etiqueta'].concat(this.componente.estilos);
        if (this.oculto) { v_clases.push('oculto'); }
        v_clases.push(typeof this.esHtml);
        v_clases.push(this.esHtml + '');
        return v_clases.join(' ');
    }

    // Propiedad 'negrita'
    private _negrita: boolean;
    @HostBinding('class.negrita')
    get negrita(): boolean { return this._negrita; }
    set negrita(p_valor: boolean) { this._negrita = p_valor; }

    // Propiedad 'cursiva'
    private _cursiva: boolean;
    @HostBinding('class.cursiva')
    get cursiva(): boolean { return this._cursiva; }
    set cursiva(p_valor: boolean) { this._cursiva = p_valor; }
}
